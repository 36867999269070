export default {
  MONOLITH: process.env.REACT_APP_PUBLIC_MONOLITH_HOST,
  LEGACY_MONOLITH: process.env.REACT_APP_PUBLIC_LEGACY_MONOLITH_HOST,
  LEGACY_API: process.env.REACT_APP_PUBLIC_LEGACY_API_HOST,
  TABLE_MANAGEMENT: process.env.REACT_APP_PUBLIC_TABLE_MANAGEMENT_HOST,
  EVENTS_SERVICE: process.env.REACT_APP_PUBLIC_EVENTS_SERVICE_HOST,
  NEWS_SERVICE: process.env.REACT_APP_PUBLIC_NEWS_SERVICE_HOST,
  USERS_SERVICE: process.env.REACT_APP_PUBLIC_USERS_SERVICE_HOST,
  PAYMENTS_SERVICE: process.env.REACT_APP_PUBLIC_PAYMENTS_SERVICE_HOST,
  CAPTCHA_SERVICE: process.env.REACT_APP_PUBLIC_CAPTCHA_SERVICE_HOST,
  OFFER_SERVICE: process.env.REACT_APP_PUBLIC_OFFER_SERVICE_HOST,
};
