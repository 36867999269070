import React, { useContext } from 'react';

const ChannelContext = React.createContext(
  new Error('useChannelState must be used within ChannelContext!')
);

export const ChannelProvider = ChannelContext.Provider;
ChannelContext.displayName = 'ChannelContext';

export function useChannelState() {
  const context = useContext(ChannelContext);
  if (context instanceof Error) throw context;
  return context;
}
