import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import PropTypes from 'prop-types';

export const GoogleCaptchaProvider = ({ children }) => (
  <GoogleReCaptchaProvider
    reCaptchaKey={process.env.REACT_APP_PUBLIC_RECAPTCHA_V3_PUBLIC_KEY}
    scriptProps={{ async: true }}
  >
    {children}
  </GoogleReCaptchaProvider>
);

GoogleCaptchaProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
