import hosts from '../hosts';
import request from '..';

export class BookingController {
  static getBookingById = ({ id }) =>
    request({
      path: `/b2c/v4/booking-attempt/${id}`,
      baseUrl: hosts.LEGACY_API,
    });

  static bookingAttempt = ({ bookingData, lang }) =>
    request({
      path: `/b2c/v4/booking-attempt?lang=${lang}`,
      method: 'POST',
      baseUrl: hosts.LEGACY_API,
      body: bookingData,
    });

  static getParticipant = ({ bookingId }) =>
    request({
      path: '/b2c/v4/tickets/token',
      baseUrl: hosts.LEGACY_API,
      queryStrings: { bookingId },
    });

  static confirmSuccessPage = ({ bookingId }) => {
    request({
      path: `/v1/bookings/${bookingId}/success-page`,
      baseUrl: hosts.LEGACY_MONOLITH,
      method: 'PATCH',
    });
  };
}
