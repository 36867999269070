import { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ErrorBoundary } from '@components/ErrorBoundary';
import { AddOns } from '@pages/AddOns';
import { Booking } from '@pages/Booking';
import { ConfirmRedirect } from '@pages/ConfirmRedirect';
import { Error } from '@pages/Error';
import { Form } from '@pages/Form';
import { Payment } from '@pages/Payment';
import { PurchaseSuccess } from '@pages/PurchaseSuccess';
import { RsvpDetails } from '@pages/RsvpDetails';
import * as Sentry from '@sentry/react';
import { CheckoutProvider } from '@services/checkoutClient';
import NotificationProvider from '@services/notifications';
import { ChannelProvider } from '@store/channel';
import { SharedDataProvider } from '@store/sharedData';

import { JukeboxProvider } from '@xceedsrl/jukebox';

const { REACT_APP_VERSION, REACT_APP_NAME } = process.env;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchOnMount: true,
      retryOnMount: true,
      optimisticResults: true,
    },
  },
});

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: `${REACT_APP_NAME}@${REACT_APP_VERSION}`,
  environment: process.env.NODE_ENV,
});

const Application = () => (
  <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <JukeboxProvider>
          <NotificationProvider>
            <CheckoutProvider>
              <SharedDataProvider>
                <ChannelProvider>
                  {/* We need to wait until the translations have been loaded */}
                  <Suspense fallback="loading">
                    <Switch>
                      <Route
                        path="/:channel/offer/:offerId"
                        exact
                        component={() => <RsvpDetails />}
                      />
                      <Route
                        path="/:channel/offer/:offerId/addons"
                        exact
                        component={() => <AddOns />}
                      />
                      <Route
                        path="/:channel/offer/:offerId/form"
                        exact
                        component={() => <Form />}
                      />
                      <Route
                        path="/:channel/payment/:bookingId"
                        exact
                        component={() => <Payment />}
                      />
                      <Route
                        path="/:channel/booking/:bookingId"
                        exact
                        component={() => <Booking />}
                      />
                      <Route
                        path="/:channel/confirm/:bookingId"
                        exact
                        component={() => <ConfirmRedirect />}
                      />
                      <Route
                        path="/:channel/error/:offerId/:errorType"
                        exact
                        component={() => <Error />}
                      />
                      <Route
                        path="/:channel/success/:offerId"
                        exact
                        component={() => <PurchaseSuccess />}
                      />
                    </Switch>
                  </Suspense>
                </ChannelProvider>
              </SharedDataProvider>
            </CheckoutProvider>
          </NotificationProvider>
        </JukeboxProvider>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </ErrorBoundary>
);

export const App = Sentry.withProfiler(Application);
