import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import AnalyticsHeader from '@components/AnalyticsHeader';
import { PurchaseSuccess } from '@components/PurchaseSuccess';
import { useBookingData } from '@hooks/useBookingData';
import { useQueryParams } from '@hooks/useQueryParams';
import { BookingController } from '@services/apiClient/interfaces/BookingController';
import { useCheckoutActions } from '@services/checkoutClient';
import { confirmPayment } from '@utils/pooling';

import { confirmRedirectPay } from '@xceedsrl/adyenjs';
import { Flex, Loader, TertiaryTitle } from '@xceedsrl/jukebox';

export const ConfirmRedirect = () => {
  const { t, i18n } = useTranslation();
  const { bookingId, channel } = useParams();
  const query = useQueryParams();
  const paymentId = query.get('paymentId');
  const sessionId = query.get('sessionId');
  const redirectResult = query.get('redirectResult');
  const { addPaymentResult } = useCheckoutActions();
  const [isSuccessPayment, setIsSuccessPayment] = useState(false);

  const { event, booking } = useBookingData({
    bookingId,
    channel,
    lang: i18n.language,
  });

  const onFailure = async error => {
    const { offerId } = await BookingController.getBookingById({ id: bookingId });
    window.location.href = `/${channel}/error/${offerId}/${error}`;
  };

  const polling = async bookingIdRedirect => {
    const confirmResponse = await confirmPayment(bookingIdRedirect);
    if (!confirmResponse.success) {
      onFailure(confirmResponse.error);
      return;
    }
    const paymentResult = confirmResponse.data;

    addPaymentResult({ ...paymentResult, bookingId });
    setIsSuccessPayment(true);
  };

  const onSuccess = response => {
    if (response.resultCode === 'Refused') {
      onFailure({ message: response.resultCode });
      return;
    }
    polling(bookingId);
  };

  useEffect(() => {
    (async function backFrom3DSecure() {
      if (bookingId && paymentId && sessionId && redirectResult) {
        confirmRedirectPay(
          sessionId,
          redirectResult,
          paymentId,
          onSuccess,
          onFailure,
          process.env.REACT_APP_PUBLIC_PAYMENTS_SERVICE_HOST,
          process.env.REACT_APP_ADYEN_CLIENT_KEY,
          process.env.REACT_APP_ADYEN_ENVIRONMENT
        );
      }
    })();
  }, []);

  return (
    <>
      {channel && <AnalyticsHeader channel={channel} />}
      {!isSuccessPayment && (
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="75%"
          width={1}
        >
          <Loader size={250} />
          <TertiaryTitle>{t('eventPage:checkoutPendingMsg')}</TertiaryTitle>
        </Flex>
      )}
      {isSuccessPayment && <PurchaseSuccess booking={booking} event={event} />}
    </>
  );
};
