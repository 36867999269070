import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Box, Text } from '@xceedsrl/jukebox';

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const CheckIcon = styled.svg`
  fill: none;
  stroke: #fff;
  stroke-width: 3px;
`;

const checkedStyles = css`
  background: ${props => (props.disabled ? '#e2e4e5' : 'rgb(59, 136, 253)')};
  border-color: ${props => (props.disabled ? '#e2e4e5' : 'rgb(59, 136, 253)')};
  ${CheckIcon} {
    visibility: 'visible';
  }
`;

const uncheckedStyles = css`
  background: #fff;
  border-color: rgb(59, 136, 253);
  ${CheckIcon} {
    visibility: 'hidden';
  }
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 18px;
  height: 18px;
  border-style: solid;
  border-width: 1.5px;
  border-radius: 2px;
  transition: all 150ms;
  opacity: ${props => props.disabled && 0.6};
  ${props => (props.checked ? checkedStyles : uncheckedStyles)};
  ${/* sc-selector */ HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 2px #f4f4f4;
  }
`;

const Label = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: ${props => (props.alignTop ? 'flex-start' : 'center')};
  cursor: pointer;
  user-select: none;
`;

/**
 * The `Checkbox` is a styled version of the native checkbox input
 */
export const Checkbox = React.memo(
  ({
    className,
    checked,
    disabled,
    children,
    dataTest,
    boxMarginBottom,
    boxMarginTop,
    childrenPaddingTop,
    alignTop,
    childrenMarginLeft,
    ...props
  }) => (
    <Box
      paddingY={3}
      width="auto"
      marginBottom={boxMarginBottom}
      marginTop={boxMarginTop}
      className={className}
      data-test={dataTest}
    >
      <Label alignTop={alignTop}>
        <Box>
          <HiddenCheckbox checked={checked} disabled={disabled} {...props} />
          <StyledCheckbox checked={checked} disabled={disabled}>
            <CheckIcon viewBox="0 0 24 24">
              <polyline points="20 6 9 17 4 12" />
            </CheckIcon>
          </StyledCheckbox>
        </Box>
        {children && (
          <Text marginLeft={childrenMarginLeft ?? 2} paddingTop={childrenPaddingTop} as="span">
            {children}
          </Text>
        )}
      </Label>
    </Box>
  )
);

Checkbox.propTypes = {
  /**
   * Define the input's checked state
   */
  checked: PropTypes.bool.isRequired,
  /**
   * Define the input's disabled state
   */
  disabled: PropTypes.bool,
  /**
   * Callback function executed when checked state change
   */
  onChange: PropTypes.func.isRequired,
  /**
   * String to be displayed next to the checkbox
   */
  children: PropTypes.node,
  /**
   * Identifier for testing
   */
  dataTest: PropTypes.string,
  /**
   * CSS class for the wrapper Box
   */
  className: PropTypes.string,
  /**
   * padding top of children
   */
  childrenPaddingTop: PropTypes.string,
  /**
   * margin left of children
   */
  childrenMarginLeft: PropTypes.string,
  /**
   * box bottom margin
   */
  boxMarginBottom: PropTypes.number,
  /**
   * box bottom margin
   */
  boxMarginTop: PropTypes.number,
  /**
   * align checkbo to top
   */
  alignTop: PropTypes.bool,
};

Checkbox.defaultProps = {
  disabled: false,
  children: '',
  dataTest: '',
  className: '',
  childrenPaddingTop: '3px',
  childrenMarginLeft: '2px',
  boxMarginBottom: 0,
  boxMarginTop: 0,
  alignTop: false,
};
