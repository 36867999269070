import { useQuery } from 'react-query';
import { BookingController } from '@services/apiClient/interfaces/BookingController';
import { ClubController } from '@services/apiClient/interfaces/ClubController';
import { EventController } from '@services/apiClient/interfaces/EventController';
import { OfferController } from '@services/apiClient/interfaces/OfferController';

export const useBookingData = ({ bookingId, channel, eventId, lang }) => {
  const {
    data: booking,
    isLoading: isBookingLoading,
    isSuccess: isBookingSuccess,
  } = useQuery(['booking-id', { bookingId }], () =>
    BookingController.getBookingById({ id: bookingId })
  );

  const {
    data: offer,
    isLoading: isOfferLoading,
    isSuccess: isOfferSuccess,
  } = useQuery(
    ['offer-id', { id: booking?.offerId }],
    () => OfferController.getOfferById({ id: booking?.offerId, channel, eventId, lang }),
    {
      enabled: !!booking,
    }
  );

  const {
    data: event,
    isLoading: isEventLoading,
    isSuccess: isEventSuccess,
  } = useQuery(
    ['event-id', { id: offer?.eventId }],
    () => EventController.getInfo({ id: eventId ?? offer?.event.id, channel }),
    {
      enabled: !!offer,
      staleTime: Infinity,
    }
  );

  const {
    data: club,
    isLoading: isClubLoading,
    isSuccess: isClubSuccess,
  } = useQuery(
    ['club-id', { id: event?.venue.id, lang, channel }],
    () => ClubController.getInfo({ id: event?.venue.id, lang, channel }),
    {
      enabled: !!event,
      staleTime: Infinity,
    }
  );

  const isLoading = isBookingLoading || isOfferLoading || isEventLoading || isClubLoading;
  const isSuccess = isBookingSuccess || isOfferSuccess || isEventSuccess || isClubSuccess;

  return {
    booking,
    event,
    offer,
    club,
    isLoading,
    isSuccess,
  };
};
