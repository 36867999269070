import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useConfiguration } from '@hooks/useConfiguration';
import { useCheckoutActions, useCheckoutState } from '@services/checkoutClient';
import GoogleTagManager from '@services/GoogleTagManager';
import PropTypes from 'prop-types';

const AnalyticsHeader = props => {
  const { channel, setLogoUrl } = props;
  const { data: configurationResponse } = useConfiguration({ channel });
  const isLogoDefault = !!configurationResponse?.logoUrl.includes('club-place-holder');
  const { addFacebookPixel } = useCheckoutActions();
  const { configuration } = useCheckoutState();
  const [googleTagManagerID, setGoogleTagManagerId] = useState();

  useEffect(() => {
    if (
      configurationResponse?.googleTagManagerId === null ||
      configurationResponse?.googleTagManagerId
    ) {
      setGoogleTagManagerId(
        configurationResponse?.googleTagManagerId ??
          process.env.REACT_APP_PUBLIC_GOOGLE_TAG_MANAGER_ID
      );
      GoogleTagManager.init();
    }
  }, [configurationResponse?.googleTagManagerId]);

  useEffect(() => {
    if (configurationResponse?.facebookPixel) {
      addFacebookPixel(configurationResponse?.facebookPixel);
    }
  }, [configurationResponse?.facebookPixel]);

  useEffect(() => {
    if (setLogoUrl && !isLogoDefault && configurationResponse?.logoUrl)
      setLogoUrl(configurationResponse.logoUrl);
  }, [setLogoUrl, isLogoDefault, configurationResponse?.logoUrl]);

  const loadGTM = () => googleTagManagerID && typeof window?.dataLayer?.push !== 'function';

  return (
    <Helmet>
      {loadGTM() && (
        <script>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${googleTagManagerID}');
            
          `}
        </script>
      )}
      {configuration?.facebookPixel && (
        <script>
          {`!function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '${configuration.facebookPixel}');
                fbq('track', 'PageView');`}
        </script>
      )}
      {configuration?.facebookPixel && (
        <noscript
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `
            <img height="1" width="1" style="display:none" 
                src={https://www.facebook.com/tr?id={${configuration.facebookPixel}}&ev=PageView&noscript=1}/>`,
          }}
        />
      )}
    </Helmet>
  );
};

AnalyticsHeader.propTypes = {
  channel: PropTypes.string.isRequired,
  setLogoUrl: PropTypes.func,
};

AnalyticsHeader.defaultProps = {
  setLogoUrl: undefined,
};

export default AnalyticsHeader;
