import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

import packages from '../package.json';

const appVersion = packages.version;

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    supportedLngs: ['ca', 'de', 'en', 'es', 'fr', 'it', 'pt'],
    debug: process.env.NODE_ENV === 'development',
    ns: ['eventPage', 'common'],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
    },
    react: {
      // We need to wait until all translations have been loaded
      useSuspense: true,
    },
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json?v=${appVersion}`,
    },
  });

export default i18n;
