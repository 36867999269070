import hosts from '../hosts';
import request from '..';

export class ChannelController {
  static getConfiguration = ({ channel }) =>
    request({
      path: `/promoters/${channel}/booking-plugin`,
      baseUrl: hosts.MONOLITH,
    });

  static getChannel = ({ channel }) => {
    if (!channel) return null;
    return request({
      path: `/v1/channels/${channel}`,
      baseUrl: hosts.EVENTS_SERVICE,
    });
  };
}
