import { useQuery } from 'react-query';
import { ChannelController } from '@services/apiClient/interfaces/ChannelController';

export function useChannel({ channelSlug }) {
  const channelQuery = ['channel'];
  const {
    data: channel,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery(channelQuery, () => ChannelController.getChannel({ channel: channelSlug }));

  return { channel, refetch, isLoading, isSuccess };
}
