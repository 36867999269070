import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Box, SecondaryText, TertiaryTitle } from '@xceedsrl/jukebox';

const LegalLink = ({ path, text }) => {
  if (path)
    return (
      <a
        href={path[0] === '/' ? `https://xceed.me${path}` : path}
        target="_blank"
        rel="noreferrer"
        style={{ color: 'black' }}
      >
        <strong>{text}</strong>
      </a>
    );

  return <strong style={{ color: 'black' }}>{text}</strong>;
};

const LegalTerms = ({ company, buttonText }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const legalText = t('common:termsCompanyBody', { buttonText });
  const legalTextSplitted = legalText.split('#####');
  const termsText = t('common:terms');
  const termsPath = `/${language}/tos`;
  const privacyText = t('common:privacy');
  const privacyPath = `/${language}/privacy-policy`;

  return (
    <Box paddingTop={3}>
      <TertiaryTitle wrapped fontSize={[7, 7, 5]} mb={0}>
        {t('common:terms')}
      </TertiaryTitle>

      <SecondaryText mt={2}>
        {legalTextSplitted[0]}
        <LegalLink path={termsPath} text={termsText} />
        {' & '}
        <LegalLink path={privacyPath} text={privacyText} />
        {legalTextSplitted[1]}
        <LegalLink path={company.privacyPolicyUrl} text={company.name} />
        {legalTextSplitted[2]}
      </SecondaryText>
    </Box>
  );
};

LegalLink.propTypes = {
  path: PropTypes.string,
  text: PropTypes.string.isRequired,
};

LegalLink.defaultProps = {
  path: null,
};

LegalTerms.propTypes = {
  company: PropTypes.object.isRequired,
  buttonText: PropTypes.string.isRequired,
};

export default LegalTerms;
