import styled from 'styled-components';

const PoweredBy = styled.span`
  color: ${props => props.theme.colors.text.primary};
  font-weight: 900;
  font-style: oblique;
`;

const Image = styled.img`
  height: 20px;
  width: auto;
  margin-left: 4px;
`;

export const Footer = () => (
  <footer>
    <PoweredBy>Powered by</PoweredBy>
    <Image src="/images/xceed.svg" alt="Xceed logo" />
  </footer>
);
