const langsPriorized = ['en', 'es', 'it', 'fr', 'pt', 'de', 'ca'];

export const getLanguageTranslation = (translations, lang, admissionType = null, t) => {
  if (!translations) {
    if (admissionType)
      return t(
        `eventPage:admission${admissionType[0].toUpperCase()}${admissionType.slice(1)}Title`
      );
    return '';
  }
  if (typeof translations === 'string') return translations;
  if (translations[lang]) return translations[lang];
  const filteredLang = langsPriorized.find(langPriorized => {
    if (translations[langPriorized]) return translations[langPriorized];
    return false;
  });
  return translations[filteredLang];
};
