import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { useClub } from '@hooks/useClub';
import { useQueryParams } from '@hooks/useQueryParams';
import { BOOKING_STATUS_FAILED } from '@models/Booking';
import hosts from '@services/apiClient/hosts';
import { BookingController } from '@services/apiClient/interfaces/BookingController';
import { EventController } from '@services/apiClient/interfaces/EventController';
import { useCheckoutState } from '@services/checkoutClient';
import GoogleTagManager from '@services/GoogleTagManager';
import { useNotify } from '@services/notifications';
import { useSharedDataActions, useSharedDataState } from '@store/sharedData';
import { formatPrice } from '@utils/format-price';
import { getLanguageTranslation } from '@utils/getLanguageTranslation';
import axios from 'axios';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Checkmark,
  Col,
  Flex,
  FormattedDate,
  Icon,
  KeyTag,
  Loader,
  Row,
  SecondaryTitle,
  Tag,
  Text,
  Title,
  useMediaQuery,
  useTimer,
} from '@xceedsrl/jukebox';

import { IconContainer } from '../sections/styledComponents';

import AnalyticsHeader from './AnalyticsHeader';

const unauthenticatedAxios = axios.create({});
const commonText = { textAlign: ['center', 'center', 'left'], mb: [1, 1, 0] };
const commonCol = { width: [1, 1, 1 / 2], paddingX: [2, 2, 4] };
const STATUSCODE_FORBIDDEN = 403;

export const PurchaseSuccess = ({ booking, event }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const notify = useNotify();
  const history = useHistory();
  const isMobile = useMediaQuery('mobile');
  const { channel = 'xceed-web', bookingId: urlBookingId } = useParams();
  const query = useQueryParams();
  const redirectUrl = query.get('redirectUrl');
  const eventId = query.get('eventId');
  const [isPDFDownloading, setIsPDFDownloading] = useState(false);
  const { paymentResult, configuration } = useCheckoutState();
  const { adyenCheckout } = useSharedDataState();
  const { setAdyenCheckout } = useSharedDataActions();
  const { status } = booking;

  useEffect(() => {
    if (adyenCheckout) {
      adyenCheckout.unmount();
      setAdyenCheckout(null);
    }
  }, [adyenCheckout]);

  if (status === 'successful' && !paymentResult) {
    history.push(
      `/${channel}/error/${urlBookingId}/${status}${eventId ? `eventId=${eventId}` : ''}`
    );
    return null;
  }

  if (!paymentResult || !event) {
    history.push(
      `/${channel}/error/${urlBookingId}/bookingerror${eventId ? `eventId=${eventId}` : ''}`
    );
    return null;
  }

  const { bookingId } = paymentResult;
  const redirectClientUrl = useCallback(
    (bookingStatus = BOOKING_STATUS_FAILED) => {
      if (!redirectUrl || !booking) {
        return null;
      }

      const url = `${redirectUrl}?bookingId=${bookingId}&bookingStatus=${bookingStatus}`;

      return bookingStatus === BOOKING_STATUS_FAILED
        ? `${url}&failureReason=${booking.failureReason ?? null}`
        : url;
    },
    [booking]
  );
  const url = redirectClientUrl(status);
  const {
    endingTime,
    startingTime,
    venue: { id, city },
  } = event;

  const { club } = useClub({ id });

  const { data: offer } = useQuery(
    ['offer-id', { id: paymentResult.offerId }],
    () =>
      EventController.getOfferById({
        id: paymentResult.offerId,
        channel,
        eventId,
        admissionType: paymentResult.offerType,
        lang: language,
      }),
    {
      enabled: !!bookingId && !!paymentResult.offerId,
    }
  );

  useQuery(
    ['confirm-success-page', { id: bookingId }],
    () =>
      BookingController.confirmSuccessPage({
        bookingId,
      }),
    {
      enabled: !!bookingId,
    }
  );

  const isTicketSIAE = offer?.settings.needsVerifiedUser;
  const today = moment();
  const passStartDate = moment.unix(offer?.passValidity?.validFrom);
  const passEndDate = moment.unix(offer?.passValidity?.validUntil);

  const { data = {} } = useQuery(
    ['ticket-download-link', { bookingId }],
    () => BookingController.getParticipant({ bookingId }),
    {
      enabled: !isTicketSIAE && !!offer && !!bookingId,
      retry: 12,
      retryDelay: attempt => 500 * 1.25 ** attempt,
    }
  );

  const { legacyId: orderId, token } = data;

  const { dd, hh, mm } = useTimer(event.startingTime);

  const manageTicket = async () => {
    if (token) {
      setIsPDFDownloading(true);
      await unauthenticatedAxios
        .get(`${hosts.LEGACY_MONOLITH}/ticket/${token}`)
        .then(() => {
          setIsPDFDownloading(false);
          window.location.href = `${hosts.LEGACY_MONOLITH}/ticket/${data.token}`;
        })
        .catch(error => {
          setIsPDFDownloading(false);
          const isTicketNotAvailable = error.response.status === STATUSCODE_FORBIDDEN;
          if (isTicketNotAvailable) {
            notify.error(error.response.data.error.message);
          }
        });
    }
  };

  const content = useMemo(
    () => ({
      in: t('common:in', { dd, hh, mm }),
      passInEndDate: t('common:passInEndDate', {
        endDate: passEndDate.format('MMMM DD, YYYY'),
      }),
      passInStartEndDate: t('common:passInStartEndDate', {
        startDate: passStartDate.format('MMMM DD, YYYY'),
        endDate: passEndDate.format('MMMM DD, YYYY'),
      }),
      date: t('common:date'),
      here: t('common:here'),
      location: t('common:location'),
      orderNumber: t('eventPage:orderNumber'),
      checkoutSentTo: t('eventPage:checkoutOrderIdSentTo', { orderId }),
      checkoutGoingTo: t('eventPage:checkoutGoingTo', { eventName: event.name }),
      checkoutPassSuccessTitle: t('eventPage:checkoutPassSuccessTitle', { clubName: club?.name }),
      checkoutEventInfo: t('eventPage:checkoutEventInfo'),
      checkoutDescription: t('eventPage:checkoutDescription'),
      checkoutDownloadTickets: t('eventPage:checkoutDownloadTickets'),
    }),
    [language, event, passEndDate, passStartDate, orderId, club, dd, hh, mm]
  );

  const TicketLink = (
    <Text {...commonText}>
      {content.checkoutDownloadTickets}
      <Button variant="text" display="inline" padding={0} size="small" onClick={manageTicket}>
        {content.here}
      </Button>
    </Text>
  );

  const offerType = offer?.admissionType ?? offer?.passType.replace('_', '');
  const isPassActive = passStartDate.isBefore(today);
  const hasEventStarted = event.startingTime * 1000 <= Date.now();

  const AddonsList = () =>
    paymentResult?.addOns?.map(addOn => {
      const addOnQuantity = addOn.quantity;
      const addOnMatch = offer.addOns.filter(offerAddOn => offerAddOn.id === addOn.id);
      if (addOnMatch.length > 0) {
        return (
          <>
            <KeyTag
              key={addOnMatch[0].id}
              textAlign={['center', 'center', 'left']}
              color="brandDark"
              mb={[1, 1, 0]}
            >
              {`${addOnQuantity} X ${addOnMatch[0].name}`}
            </KeyTag>
            <Text {...commonText} lineClamp={1}>
              {addOnMatch[0]?.description}
            </Text>
          </>
        );
      }
      return null;
    });

  useEffect(() => {
    if (offer && paymentResult && id) {
      let total = 0;
      let totalFee = 0;
      if (paymentResult?.quantity && offer?.price.amount) {
        if (offer?.commissions.transactionFee)
          totalFee = paymentResult.quantity * offer.commissions.transactionFee;
        total = paymentResult?.quantity * offer.price.amount + totalFee;
      }
      let totalAddOns = 0;
      if (paymentResult?.addOns.length > 0) {
        paymentResult?.addOns.forEach(paymentAddOn => {
          const addOnMatch = offer.addOns.filter(offerAddOn => offerAddOn.id === paymentAddOn.id);
          if (addOnMatch.length > 0) {
            totalAddOns += addOnMatch.reduce(
              (totalAdd, addOn) => totalAdd + addOn.fixedPrice.amount * paymentAddOn.quantity,
              0
            );
          }
        });
      }
      if (event && offer) {
        const shoppingCart = {
          total: total + totalAddOns,
          totalFee,
        };

        if (typeof window?.dataLayer?.push === 'function')
          GoogleTagManager.purchase({
            offer,
            event,
            shoppingCart,
            channel,
            quantity: booking.quantity,
            addOnsSelected: paymentResult.addOns,
            bookingId,
          });

        if (configuration?.facebookPixel) {
          // eslint-disable-next-line no-undef
          fbq('track', 'Purchase', {
            currency: offer?.price.currency || 'EUR',
            value: total + totalAddOns,
            content_name: event?.name,
            content_ids: event?.id,
            content_type: `${offer?.admissionType}${offer?.name ? ` - ${offer?.name}` : ''}`,
            num_items: paymentResult.bookingQuantity,
            contents: [
              {
                id: offer?.id,
                quantity: offer.bookingQuantity,
              },
              ...paymentResult.addOns.map(addOn => ({ id: addOn.id, quantity: addOn.quantity })),
            ],
          });
        }
      }
    }
    if (url) {
      window.location.href = url;
    }
  }, [offer, paymentResult, id, configuration]);

  if (url) return null;

  return (
    <Flex height="100vh" justifyContent="center" alignItems="center">
      {typeof window.gtag !== 'function' && channel && false && (
        <AnalyticsHeader channel={channel} />
      )}
      {isPDFDownloading && <Loader size={250} />}
      {!isPDFDownloading && (
        <Flex
          width={[1, 2 / 3]}
          paddingX={[2, 3]}
          flexDirection="column"
          justifyContent="space-between"
        >
          <Row justifyContent="center" data-testid="purchase-success--header">
            <IconContainer bg="feeling.positive">
              <Icon fontSize={12} color="brandLight">
                <Checkmark />
              </Icon>
            </IconContainer>
          </Row>

          <Row justifyContent="center">
            <Title textAlign="center">
              {offer?.passType ? content.checkoutPassSuccessTitle : content.checkoutGoingTo}
            </Title>
          </Row>

          {!hasEventStarted && offer?.admissionType && (
            <Row justifyContent="center" mt={2}>
              <SecondaryTitle fontWeight="normal" textAlign="center">
                {content.in}
              </SecondaryTitle>
            </Row>
          )}
          {offer?.passType && (
            <Row justifyContent="center" mt={2}>
              <SecondaryTitle fontWeight="normal" textAlign="center">
                {isPassActive ? content.passInEndDate : content.passInStartEndDate}
              </SecondaryTitle>
            </Row>
          )}

          {offer && (
            <Row mb={3} mt={[5, 6]}>
              <Col {...commonCol} mb={[3, 3, 0]}>
                <Tag {...commonText}>{content.checkoutDescription}</Tag>
                <KeyTag textAlign={['center', 'center', 'left']} color="brandDark" mb={[1, 1, 0]}>
                  {`${paymentResult.quantity} x ${
                    getLanguageTranslation(offer.name, language, offerType, t) || offer.typeText
                  }`}
                </KeyTag>
                <Text {...commonText} lineClamp={2}>
                  {offer.description}
                </Text>
                <Box pt={2} pl={3}>
                  {AddonsList()}
                </Box>
                {!!offer.price.offlinePrice && (
                  <Text fontWeight="900" color="#fbb86d" pt={2} {...commonText}>
                    {`${t('eventPage:successPageDownPaymentText')} ${formatPrice(
                      offer.price.offlinePrice * paymentResult.quantity,
                      offer.price.currency,
                      language
                    )}`}
                  </Text>
                )}
              </Col>
              <Col {...commonCol}>
                <Tag {...commonText}>{content.checkoutSentTo}</Tag>
                <Text {...commonText}>{paymentResult.bookingFormFields.email}</Text>
                {!isTicketSIAE && TicketLink}
              </Col>
            </Row>
          )}
          <Row>
            <Col {...commonCol} mb={[3, 3, 0]}>
              {!!offer?.admissionType && (
                <>
                  <Tag {...commonText}>{content.checkoutEventInfo}</Tag>
                  <Text {...commonText}>
                    {content.location}: {club?.address}
                  </Text>
                  <FormattedDate
                    startingTime={startingTime}
                    endingTime={endingTime}
                    lang={language}
                    timeZone={city.timezone}
                    expanded={!isMobile}
                  >
                    {({ startDate, startTime, endTime }) => (
                      <Text {...commonText}>
                        {content.date}: {startDate} | {startTime} - {endTime}
                      </Text>
                    )}
                  </FormattedDate>
                </>
              )}
            </Col>
            <Col {...commonCol}>{orderId && !isTicketSIAE && !offer && TicketLink}</Col>
          </Row>
        </Flex>
      )}
    </Flex>
  );
};

PurchaseSuccess.Header = () => {
  const { t } = useTranslation();
  return (
    <KeyTag textAlign="center" color="brandDark" fontSize={2}>
      {t('eventPage:checkoutConfirmed')}
    </KeyTag>
  );
};

PurchaseSuccess.propTypes = {
  booking: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
};
