import { isOfferSalesClosed } from '@utils/is-offer-sales-closed';
import { isOfferSoldout } from '@utils/is-offer-soldout';

export const isOfferBuyable = offer => {
  const isSellableStartingTime = offer?.startingTime
    ? new Date(offer?.startingTime * 1000) < new Date()
    : true;

  const isSellableClosingTime = offer?.closingTime
    ? new Date(offer?.closingTime * 1000) > new Date()
    : true;

  return (
    !isOfferSoldout(offer) &&
    !isOfferSalesClosed(offer) &&
    isSellableStartingTime &&
    isSellableClosingTime &&
    offer?.availableTickets !== 0
  );
};
