import { createContext, useContext, useMemo, useReducer } from 'react';

const SharedDataContextState = createContext(
  new Error('useSharedDataState must be used within SharedDataProvider!')
);

const SharedDataContextActions = createContext(
  new Error('useSharedDataActions must be used within SharedDataProvider!')
);

SharedDataContextState.displayName = 'SharedDataContextState';
SharedDataContextActions.displayName = 'SharedDataContextActions';

const reducer = (state, action) =>
  typeof action === 'function' ? action(state) : { ...state, ...action };

const initialState = {
  bookingHoldToken: null,
};

export const SharedDataProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const actions = useMemo(
    () => ({
      setAdyenCheckout: adyenCheckout => dispatch({ adyenCheckout }),
      setBookingHoldToken: bookingHoldToken => dispatch({ bookingHoldToken }),
      addParticipants: participants => dispatch({ participants }),
      setAddOns: addOns => dispatch({ addOns }),
      dispatch,
    }),
    [dispatch]
  );

  return (
    <SharedDataContextActions.Provider value={actions}>
      <SharedDataContextState.Provider value={state} {...props} />
    </SharedDataContextActions.Provider>
  );
};

export function useSharedDataState() {
  const context = useContext(SharedDataContextState);
  if (context instanceof Error) throw context;
  return context;
}

export function useSharedDataActions() {
  const context = useContext(SharedDataContextActions);
  if (context instanceof Error) throw context;
  return context;
}
