import { City } from '@models/City';

const defaultClub = {
  id: null,
  legacyId: null,
  name: null,
  about: null,
  slug: null,
  coverUrl: null,
  logoUrl: null,
  address: null,
  coordinates: {},
  settings: {
    isPrivate: false,
  },
  city: new City(),
  followersCount: null,
  musicGenres: [],
  vibes: [],
  facilities: [],
  foodType: [],
  characteristics: [],
  socialMedia: [],
  followers: [],
  gallery: [],
};

export class Club {
  constructor(club = defaultClub) {
    Object.assign(this, Club.parse({ ...defaultClub, ...club }));
  }

  static parse(club) {
    const { city, coverUrl, gallery } = club;
    return {
      ...club,
      city: new City(city),
      gallery: [{ id: 'gallery-cover', imageUrl: coverUrl }, ...gallery],
    };
  }
}
