import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PageWrapper } from '@components/PageWrapper';
import { EventController } from '@services/apiClient/interfaces/EventController';
import { useCheckoutState } from '@services/checkoutClient';
import { GoogleCaptchaProvider } from '@services/googleCaptchaProvider/GoogleCaptchaProvider';
import GoogleTagManager from '@services/GoogleTagManager';
import { useNotify } from '@services/notifications';
import { useSharedDataActions, useSharedDataState } from '@store/sharedData';
import { ERROR_CODE_OFFER_TM_NOT_ENOUGH_PLACES } from '@utils/error-codes';
import { formatPrice } from '@utils/format-price';
import { getLanguageTranslation } from '@utils/getLanguageTranslation';
import { isOfferBuyable } from '@utils/is-offer-buyable';
import { isOfferSalesClosed } from '@utils/is-offer-sales-closed';
import { isOfferSoldout } from '@utils/is-offer-soldout';
import PropTypes from 'prop-types';

import { Counter, Offer } from '@xceedsrl/jukebox';

import { GoogleRecaptcha } from './GoogleCaptcha';

export const RsvpDetails = ({ offer, event, shoppingCart, channel, changeQty }) => {
  const history = useHistory();
  const { t, locale, i18n } = useTranslation();
  const lang = i18n.language;

  const [isFormValid, setIsFormValid] = useState(true);
  const [isStepValid, setIsStepValid] = useState(false);
  const [hasCaptchaBeenValidated, setHasCaptchaBeenValidated] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const notify = useNotify();
  const { setBookingHoldToken, setAdyenCheckout } = useSharedDataActions();
  const { configuration } = useCheckoutState();
  const { adyenCheckout } = useSharedDataState();
  const offerType = offer.admissionType ?? offer.passType.replace('_', '');

  useEffect(() => {
    if (adyenCheckout) {
      adyenCheckout.unmount();
      setAdyenCheckout(null);
    }
  }, [adyenCheckout]);

  useEffect(() => {
    if (typeof window?.dataLayer?.push === 'function')
      GoogleTagManager.viewItem({ offer, event, shoppingCart, channel });
  }, []);

  const content = useMemo(
    () => ({
      endIn: t('eventPage:offerEndIn'),
      soldOut: t('eventPage:offerSoldout'),
      onlyLeft: t('eventPage:offerOnlyLeft', { count: offer?.quantity }),
      notForSale: t('eventPage:offerNotForSale'),
      salesEnded: t('eventPage:offerSalesEnded'),
      pay: t('common:pay'),
      confirm: t('common:confirm'),
      checkout: t('common:checkout'),
      continue: t('common:continue'),
      tryAgain: t('common:tryAgain'),
      shareWithFriends: t('common:shareWithFriends'),
      downPayment: t('eventPage:offerDownpayment'),
      offlinePrice: t('eventPage:offerDownpaymentOnPremise'),
      entranceValidityTime: t('eventPage:entranceValidityTime'),
      passValidity: t('eventPage:passValidity'),
      to: t('common:toWord'),
      from: t('common:fromWord'),
      until: t('common:untilWord'),
    }),
    [lang, offer]
  );

  const handleSubmit = async () => {
    const { bookingQuantity } = shoppingCart.products[shoppingCart.selectedOffer];

    if (offer.tableManagementOfferId) {
      try {
        const response = await EventController.holdTicketTableManagement({
          tableManagementOfferId: offer.tableManagementOfferId,
          quantity: offer.quantity,
        });

        const { bookingHoldToken } = response;
        setBookingHoldToken(bookingHoldToken);
      } catch (error) {
        if (error.code === ERROR_CODE_OFFER_TM_NOT_ENOUGH_PLACES) {
          notify.info(t('common:errorOfferTmNotEnoughPlaces', { offerName: offer.name }));
          return;
        }
        notify.info(error.message || error);
        return;
      }
    }
    if (typeof window?.dataLayer?.push === 'function')
      GoogleTagManager.addToCart({ offer, event, shoppingCart, quantity, channel });

    if (configuration?.facebookPixel)
      // eslint-disable-next-line no-undef
      fbq('track', 'AddToCart', {
        value: shoppingCart.total,
        currency: offer?.price.currency,
        content_name: event?.name,
        content_ids: event?.id,
        content_type: `${offer?.admissionType}${offer?.name ? ` - ${offer?.name}` : ''}`,
        num_items: quantity,
        contents: [
          {
            id: offer?.id,
            quantity,
          },
        ],
      });
    const urlParamRedirectUrl = configuration.redirectUrl
      ? `redirectUrl=${configuration.redirectUrl}&`
      : '';

    const eventId = configuration.passInfo ? `eventId=${configuration.passInfo}&` : '';

    const areAddonAvailables =
      offer?.addOns.length > 0 &&
      offer.addOns.some(addOn => addOn.availableItems >= quantity || addOn.availableItems === null);
    const nextStep = areAddonAvailables ? 'addons' : 'form';
    history.push(
      `/${channel}/offer/${offer.id}/${nextStep}?${urlParamRedirectUrl}${eventId}quantity=${bookingQuantity}`
    );
  };

  const hasToPay = shoppingCart.total > 0 && !offer.settings.isPayAtDoor;
  const buttonText = hasToPay ? content.checkout : content.continue;

  useEffect(() => {
    setQuantity(offer?.itemsPerTransaction?.min || 0);
    if (offer.settings?.italyRegulation?.needsCaptcha) {
      setIsFormValid(false);
    }

    return () => {
      if (offer.settings?.italyRegulation?.needsCaptcha) {
        setIsFormValid(true);
      }
    };
  }, [offer]);

  useEffect(() => {
    const isOfferValid = isOfferBuyable(offer);
    const isCaptchaValid = offer.settings?.italyRegulation?.needsCaptcha
      ? hasCaptchaBeenValidated
      : true;
    const isValidAll =
      isCaptchaValid && isOfferValid && !(isOfferSalesClosed(offer) || isOfferSoldout(offer));
    setIsStepValid(isValidAll);
    setIsFormValid(isValidAll);
  }, [hasCaptchaBeenValidated, offer, setIsStepValid]);

  const handleChangeQuantity = quantityValue => {
    setQuantity(quantityValue);
    changeQty(quantityValue, offer.id);
  };

  return (
    <GoogleCaptchaProvider>
      <PageWrapper
        buttonText={buttonText}
        isFormValid={isFormValid}
        handleSubmit={handleSubmit}
        isDisabled={!isStepValid}
      >
        <Offer
          key={offer.id}
          price={
            offer.price
              ? formatPrice(offer.price.amount, offer.price.currency, lang)
              : t('eventPage:free')
          }
          onlinePrice={
            offer.price.onlinePrice && offer.price.onlinePrice < offer.price.amount
              ? formatPrice(offer.price.onlinePrice, offer.price.currency, locale)
              : null
          }
          offlinePrice={
            offer.price.offlinePrice && offer.price.offlinePrice < offer.price.amount
              ? formatPrice(offer.price.offlinePrice, offer.price.currency, locale)
              : null
          }
          name={getLanguageTranslation(offer.name, lang, offerType, t)}
          description={getLanguageTranslation(offer.description, lang)}
          isSalesClosed={isOfferSalesClosed(offer) || isOfferSoldout(offer)}
          availableTickets={offer.quantity}
          startingTime={offer.salesTime.openingTime}
          closingTime={offer.salesTime.closingTime}
          guestCapacity={
            offer.guestCapacity && t('eventPage:guestCapacity', { count: offer.guestCapacity })
          }
          i18n={content}
          type={offer.passType ? 'pass' : 'admission'}
          passValidityFrom={offer.passValidity?.validFrom ?? null}
          passValidityUntil={offer.passValidity?.validUntil ?? null}
          checkInTimeFrom={offer.checkInTime?.validFrom}
          checkInTimeUntil={offer.checkInTime?.validUntil}
          boxShadow={0}
          paddingX={0}
          alignItems="flex-start"
          isPayAtDoor={offer.onlinePaymentPercentage === 0 && offer.price.amount !== 0}
          circleColor={offer?.color}
        />
        <Counter
          initialValue={offer.itemsPerTransaction.min}
          maxValue={offer.itemsPerTransaction.max}
          minValue={offer.itemsPerTransaction.min}
          onChange={value => handleChangeQuantity(value)}
          disabled={!isOfferBuyable(offer)}
        />
        <GoogleRecaptcha
          needsCaptcha={offer.settings?.italyRegulation?.needsCaptcha}
          actionName="booking_plugin_checkout"
          setCaptchaValid={setHasCaptchaBeenValidated}
        />
      </PageWrapper>
    </GoogleCaptchaProvider>
  );
};

RsvpDetails.propTypes = {
  offer: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  shoppingCart: PropTypes.object.isRequired,
  changeQty: PropTypes.func.isRequired,
  channel: PropTypes.string.isRequired,
};
