import { useTranslation } from 'react-i18next';
import { ExpansiveSummary, Sidebar } from '@components/Checkout/components';
import { Container } from '@components/Container';
import { Footer } from '@components/Footer';
import { Header } from '@components/Header';
import { useCheckoutState } from '@services/checkoutClient';
import { formatPrice } from '@utils/format-price';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Box,
  Button,
  Flex,
  Grid,
  SecondaryText,
  TertiaryTitle,
  useMediaQuery,
} from '@xceedsrl/jukebox';

const CheckoutButtonWrapperBackground = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: ${props => props.theme.colors.background.spaceWhite};
`;

const CheckoutButtonWrapper = styled.div`
  padding: 24px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const PageWrapper = ({
  children,
  buttonText,
  isFormValid,
  handleSubmit,
  isDisabled,
  isPayButtonClicked,
}) => {
  const { t, locale } = useTranslation();
  const isMobile = useMediaQuery('mobile');
  const { shoppingCart } = useCheckoutState();
  const offer = shoppingCart.products[shoppingCart.selectedOffer];

  return (
    <>
      <Header offer={offer} />
      <div>
        <Container>
          <Grid
            paddingTop={40}
            width={1}
            gridGap={3}
            gridTemplateColumns={['1fr', '3fr 2fr', '2fr 1fr']}
            gridTemplateRows={['1fr auto', '1fr']}
            marginBottom={132}
          >
            <Box paddingLeft={[3, 4]} paddingRight={[3, 0]}>
              {children}
            </Box>
            {!isMobile && (
              <Box
                borderLeft="1px solid"
                borderColor="background.mercury"
                paddingX={3}
                paddingBottom="30px"
              >
                <Sidebar display={['none', 'block']} />
              </Box>
            )}
            {isMobile && (
              // Same height as the expansive summary
              <Box marginTop={132}>
                <ExpansiveSummary
                  isDisabled={isDisabled}
                  buttonText={buttonText}
                  onSubmit={handleSubmit}
                  isFormValid={isFormValid}
                  isPayButtonClicked={isPayButtonClicked}
                />
              </Box>
            )}
          </Grid>
        </Container>

        {!isMobile && (
          <CheckoutButtonWrapperBackground>
            <Container>
              <CheckoutButtonWrapper>
                <Footer />
                <Flex width={1} justifyContent="end">
                  <Box flexBasis="50%" marginRight="20px">
                    <SecondaryText color="text.caption" textAlign="right">
                      {t('eventPage:feesTaxesLabelText')}
                    </SecondaryText>
                    <TertiaryTitle as="div" textAlign="right">
                      {formatPrice(shoppingCart.total, offer?.price.currency, locale)}
                    </TertiaryTitle>
                  </Box>
                  <Button
                    id="checkout-btn"
                    variant="primary"
                    borderRadius="6px"
                    type="button"
                    disabled={!isFormValid || isDisabled || isPayButtonClicked}
                    onClick={handleSubmit}
                    size="medium"
                    width="auto"
                    paddingX={50}
                    paddingY={20}
                  >
                    {buttonText}
                  </Button>
                </Flex>
              </CheckoutButtonWrapper>
            </Container>
          </CheckoutButtonWrapperBackground>
        )}
      </div>
    </>
  );
};

PageWrapper.defaultProps = {
  isPayButtonClicked: false,
};

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  buttonText: PropTypes.string.isRequired,
  isFormValid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isPayButtonClicked: PropTypes.bool,
};
