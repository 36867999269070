/**
 * ===========================================================================================================
 * This api client interface should help to centralize the validation of requests and abstract repetitive code.
 * ===========================================================================================================
 */

import hosts from './hosts';
/**
 * Validation helpers
 */
function _missingParams() {
  throw new TypeError('The "request" function requires a parameters object as argument.');
}

function _requiredParam(param) {
  throw new TypeError(`The required ${param} parameter is missing.`);
}

/**
 * Should take care on no-existing response body and failed request
 * @param {XMLHttpRequest.response} response
 * @returns {Promise<object>}
 */
async function _parseRequest(response) {
  const { data, error, success } =
    response.status !== 204 ? await response.json() : { success: true };
  if (response.status === 404) {
    throw new Error(error.message, response.url);
  }
  if (response.status >= 400 || !success) {
    throw new Error(error.message, response.status, response.url);
  }
  return data;
}

/**
 * Should download an attachment
 * @param {XMLHttpRequest.response} response
 * @returns {Promise<object>}
 */
async function _downloadAttachment(response) {
  const blob = await response.blob();
  const a = document.createElement('a');

  a.download = blob.name || 'ticket';
  a.rel = 'noopener';
  a.target = '_blank';
  a.href = window.URL.createObjectURL(blob);
  a.dispatchEvent(new MouseEvent('click'));
  window.URL.revokeObjectURL(a.href);

  return true;
}

/**
 *
 * @param {object} params
 * @returns {Promise<object>}
 */
function request(params = _missingParams()) {
  const {
    path = _requiredParam('path'),
    baseUrl = hosts.MONOLITH,
    method = 'GET',
    body,
    headers = {},
    queryStrings = null,
    ...extraOpts
  } = params;

  const reqOptions = {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    ...extraOpts,
  };

  if (body && body instanceof FormData) {
    reqOptions.body = body;
    delete reqOptions.headers['Content-Type'];
  } else {
    reqOptions.body = JSON.stringify(body);
  }

  let parsedQueries = '';
  let encodedParams = '';

  if (queryStrings) {
    parsedQueries = Object.entries(queryStrings)
      .filter(([, value]) => value !== undefined && value !== null && value !== '')
      .map(([key, value]) =>
        typeof value === 'object'
          ? `${key}=${encodeURIComponent(JSON.stringify(value))}`
          : `${key}=${encodeURIComponent(value)}`
      )
      .join('&');
    encodedParams = parsedQueries && `?${parsedQueries}`;
  }

  return fetch(`${baseUrl}${path}${encodedParams}`, reqOptions).then(
    extraOpts.download ? _downloadAttachment : _parseRequest
  );
}

export default request;
