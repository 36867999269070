import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Header } from '@components/Header';
import { useOfferEvent } from '@hooks/useOfferEvent';
import { useCheckoutActions, useCheckoutState } from '@services/checkoutClient';

import { Error as ErrorComponent } from '../components/Error';

export const Error = () => {
  const { i18n } = useTranslation();
  const { channel, offerId } = useParams();
  const { shoppingCart } = useCheckoutState();
  const offer = shoppingCart.products[shoppingCart.selectedOffer];
  const { event } = useOfferEvent({ offerId: offer?.id ?? offerId, channel, lang: i18n.language });
  const { addEventInfo } = useCheckoutActions();

  useEffect(() => {
    if (event) {
      addEventInfo(event);
    }
  }, [event]);

  return (
    <>
      <Header offer={offer} />
      <ErrorComponent channel={channel} offerId={offer?.id ?? offerId} />
    </>
  );
};
