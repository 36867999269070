/* eslint-disable consistent-return */
import { BookingController } from '@services/apiClient/interfaces/BookingController';

function _delay(wait) {
  return new Promise(res => setTimeout(res, wait));
}

export async function confirmPayment(id, attempts = 12, wait = 500) {
  try {
    const data = await BookingController.getBookingById({ id });

    if (data.status === 'successful') {
      return { data, success: true };
    }

    if (data.status === 'failed') {
      return { data, success: false };
    }

    if (data.status === 'pending' && attempts) {
      const newWait = wait * 1.25;
      await _delay(newWait);
      return confirmPayment(id, attempts - 1, newWait);
    }

    if (!attempts) {
      throw new Error(
        "This is taking longer than usual. If you didn't receive your ticket please contact us at support@xceed.me."
      );
    }
  } catch (error) {
    return { success: false, error };
  }
}
