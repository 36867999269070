import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { BOOKING_STATUS_PENDING } from '@models/Booking';
import { BookingController } from '@services/apiClient/interfaces/BookingController';
import { EventController } from '@services/apiClient/interfaces/EventController';
import { OfferController } from '@services/apiClient/interfaces/OfferController';

const MAX_ATTEMPTS = 12;

export const useBookingStatus = ({ bookingId, eventId, channel }) => {
  const { t } = useTranslation();
  const [isPolling, setIsPolling] = useState(true);
  const [interval, setInterval] = useState(500);
  const [attempt, setAttempt] = useState(0);

  const {
    data: booking,
    isLoading: isBookingLoading,
    isSuccess: isBookingSuccess,
    isError: isBookingError,
  } = useQuery(
    ['booking-id', { bookingId }],
    async () => {
      const response = await BookingController.getBookingById({ id: bookingId });

      if (attempt === MAX_ATTEMPTS) {
        setIsPolling(false);
        throw new Error(t('bookingErrorStatus'));
      }

      if (response.status !== BOOKING_STATUS_PENDING) {
        setIsPolling(false);
      }

      setInterval(interval * 1.25);
      setAttempt(attempt + 1);
      return { ...response };
    },
    {
      enabled: isPolling,
      refetchInterval: interval,
    }
  );

  const {
    data: offer,
    isLoading: isOfferLoading,
    isSuccess: isOfferSuccess,
  } = useQuery(
    ['offer-id', { id: booking?.offerId }],
    () => OfferController.getOfferById({ id: booking?.offerId, channel, eventId }),
    {
      enabled: !!booking,
    }
  );

  const {
    data: event,
    isLoading: isEventLoading,
    isSuccess: isEventSuccess,
  } = useQuery(
    ['event-id', { id: offer?.eventId }],
    () => EventController.getInfo({ id: eventId ?? offer?.event.id, channel }),
    {
      enabled: !!offer,
      staleTime: Infinity,
    }
  );

  const isLoading = isBookingLoading || isOfferLoading || isEventLoading;
  const isSuccess = isBookingSuccess || isOfferSuccess || isEventSuccess;

  return {
    offer,
    event,
    booking,
    isLoading,
    isSuccess,
    isBookingError,
  };
};
