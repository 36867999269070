import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;

  @media ${props => props.theme.mediaQueries.mobile} {
    max-width: 100%;
  }

  @media ${props => props.theme.mediaQueries.tablet} {
    max-width: 100%;
  }
`;
