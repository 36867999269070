import hosts from '../hosts';
import request from '..';

export class GoogleRecaptchaController {
  static getValidation = async ({ token }) => {
    try {
      const result = await request({
        path: `/v1/google/recaptcha/${token}`,
        method: 'POST',
        baseUrl: hosts.LEGACY_MONOLITH,
      });

      return result.score;
    } catch (_) {
      return 0;
    }
  };
}
