export function getActions(dispatch) {
  async function addProduct(product, addOns) {
    dispatch({ type: 'addProduct', payload: { product, addOns } });
  }

  async function addProductWithQuantity(product, addOns) {
    dispatch({ type: 'addProductWithQuantity', payload: { product, addOns } });
  }

  function addParticipants(participants) {
    dispatch({ type: 'addParticipants', payload: participants });
  }

  function changeQty(newQty, productId, addOns) {
    dispatch({ type: 'changeQty', payload: { newQty, productId, addOns } });
  }

  function addPaymentResult(paymentResult) {
    dispatch({ type: 'addPaymentResult', payload: paymentResult });
  }

  function addEventInfo(eventInfo) {
    dispatch({ type: 'addEventInfo', payload: eventInfo });
  }

  function addPassInfo(eventId) {
    dispatch({ type: 'addPassInfo', payload: eventId });
  }

  function addRedirectUrl(redirectUrl) {
    dispatch({ type: 'addRedirectUrl', payload: redirectUrl });
  }

  function addFacebookPixel(facebookPixel) {
    dispatch({ type: 'addFacebookPixel', payload: facebookPixel });
  }

  function updateCostsWithAddons(addOns, productId) {
    dispatch({ type: 'updateCostsWithAddons', payload: { addOns, productId } });
  }

  return {
    addRedirectUrl,
    addProductWithQuantity,
    addProduct,
    changeQty,
    addPaymentResult,
    addEventInfo,
    addPassInfo,
    addParticipants,
    updateCostsWithAddons,
    addFacebookPixel,
  };
}
