/* eslint-disable no-param-reassign */
export const initialState = {
  shoppingCart: {
    selectedOffer: null,
    products: {},
    subTotal: 0,
    totalFee: 0,
    totalDDP: 0,
    total: 0,
  },
  event: null,
  configuration: {
    redirectUrl: '',
    facebookPixel: '',
  },
};

function updateCosts(products, addOns) {
  return Object.values(products).reduce(
    (costs, prod) => {
      let addOnsTotalCost = 0;
      if (addOns)
        addOnsTotalCost =
          Math.trunc(
            addOns.reduce((addOnsCosts, addOn) => {
              addOnsCosts += addOn.price * addOn.quantity;
              return addOnsCosts;
            }, 0) * 100
          ) / 100;
      costs.totalDDP +=
        (prod?.settings?.italyRegulation?.dirittoPrevendita || 0) * prod.bookingQuantity;
      costs.subTotal +=
        (prod.price.onlinePrice ?? prod.price.amount) * prod.bookingQuantity + addOnsTotalCost;
      costs.totalFee += (prod.commissions.transactionFee || 0) * prod.bookingQuantity;
      costs.total = costs.totalDDP + costs.subTotal + costs.totalFee;
      if (prod.onlinePaymentPercentage === 0) costs.total = 0;
      return costs;
    },
    {
      totalDDP: 0,
      subTotal: 0,
      totalFee: 0,
      total: 0,
    }
  );
}

function updateCostsWithAddons(products, addOns) {
  return Object.values(products).reduce(
    (costs, prod) => {
      const addOnsTotalCost =
        Math.trunc(
          addOns.reduce((addOnsCosts, addOn) => {
            addOnsCosts += addOn.price * addOn.quantity;
            return addOnsCosts;
          }, 0) * 100
        ) / 100;
      costs.totalDDP +=
        (prod?.settings?.italyRegulation?.dirittoPrevendita || 0) * prod.bookingQuantity;
      costs.subTotal +=
        (prod.price.onlinePrice ?? prod.price.amount) * prod.bookingQuantity + addOnsTotalCost;
      costs.totalFee += (prod.commissions.transactionFee || 0) * prod.bookingQuantity;
      costs.total = costs.totalDDP + costs.subTotal + costs.totalFee;
      if (prod.onlinePaymentPercentage === 0) costs.total = 0;
      return costs;
    },
    {
      totalDDP: 0,
      subTotal: 0,
      totalFee: 0,
      total: 0,
    }
  );
}

let products;
let costs;

export function reducer(state = initialState, action) {
  switch (action.type) {
    case 'addRedirectUrl': {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          redirectUrl: action.payload,
        },
      };
    }
    case 'addFacebookPixel': {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          facebookPixel: action.payload,
        },
      };
    }
    case 'addProduct':
      products = {
        [action.payload.product.id]: {
          ...state.shoppingCart.products[action.payload.product.id],
          ...action.payload.product,
          bookingQuantity: action.payload.product.itemsPerTransaction.min || 1,
        },
      };
      costs = updateCosts(products, action.payload.addOns);
      return {
        ...state,
        shoppingCart: {
          ...state.shoppingCart,
          products,
          selectedOffer: action.payload.product.id,
          ...costs,
        },
      };
    case 'addParticipants': {
      return {
        ...state,
        shoppingCart: {
          ...state.shoppingCart,
          participants: action.payload,
        },
      };
    }
    case 'updateCostsWithAddons':
      products = {
        ...state.shoppingCart.products,
      };
      costs = updateCostsWithAddons(products, action.payload.addOns);
      return {
        ...state,
        shoppingCart: {
          ...state.shoppingCart,
          products,
          selectedOffer: action.payload.productId,
          ...costs,
        },
      };

    case 'addProductWithQuantity':
      products = {
        [action.payload.product.id]: {
          ...state.shoppingCart.products[action.payload.product.id],
          ...action.payload.product,
        },
      };
      costs = updateCosts(products, action.payload.addOns);
      return {
        ...state,
        shoppingCart: {
          ...state.shoppingCart,
          products,
          selectedOffer: action.payload.product.id,
          ...costs,
        },
      };
    case 'emptyCart':
      return {
        ...state,
        shoppingCart: initialState.shoppingCart,
      };
    case 'changeQty':
      products = {
        ...state.shoppingCart.products,
        [action.payload.productId]: {
          ...state.shoppingCart.products[action.payload.productId],
          bookingQuantity: action.payload.newQty,
        },
      };
      costs = updateCosts(products, action.payload.addOns);
      return {
        ...state,
        shoppingCart: {
          ...state.shoppingCart,
          products,
          ...costs,
        },
      };
    case 'addEventInfo':
      return {
        ...state,
        event: action.payload,
      };
    case 'addPassInfo':
      return {
        ...state,
        configuration: {
          ...state.configuration,
          passInfo: action.payload,
        },
      };
    case 'addPaymentResult': {
      return {
        ...state,
        paymentResult: {
          ...action.payload,
          bookingFormFields:
            action.payload.bookingFormFields &&
            action.payload.bookingFormFields.reduce(
              (list, field) => ({ ...list, [field.name]: field.value }),
              {}
            ),
        },
      };
    }
    default:
      return state;
  }
}
