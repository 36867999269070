import styled from 'styled-components';

import { Flex } from '@xceedsrl/jukebox';

export const SectionWrapper = styled.div`
  margin-bottom: 24px;
`;

export const IconContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
  min-width: 96px;
  min-height: 96px;
  border-radius: 50%;
  margin-bottom: 31px;
`;
