import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Booking as BookingComponent } from '@components/Booking';
import { useBookingStatus } from '@hooks/useBooking';
import { useQueryParams } from '@hooks/useQueryParams';
import { BOOKING_STATUS_FAILED } from '@models/Booking';
import { useCheckoutActions } from '@services/checkoutClient';
import { useSharedDataState } from '@store/sharedData';
import { confirmPayment } from '@utils/pooling';

export const Booking = () => {
  const { bookingId, channel } = useParams();
  const query = useQueryParams();
  const redirectUrl = query.get('redirectUrl');
  const eventId = query.get('eventId');
  const { offer, booking, isBookingError, event, isLoading } = useBookingStatus({
    bookingId,
    eventId,
    channel,
  });
  const { addEventInfo, addProductWithQuantity, addPaymentResult } = useCheckoutActions();
  const { addOns } = useSharedDataState();

  useEffect(() => {
    if (event) {
      addEventInfo(event);
    }
  }, [event]);

  useEffect(() => {
    const confirmPaymentHandler = async () => {
      const response = await confirmPayment(bookingId);
      const paymentResult = response.data;
      addPaymentResult({ ...paymentResult, bookingId });
    };

    confirmPaymentHandler();
  }, [bookingId]);

  useEffect(() => {
    if (offer) {
      addProductWithQuantity({ ...offer, quantity: booking.quantity }, addOns);
    }
  }, [offer, booking]);

  if (isLoading === true) return null;

  if (!event || !booking || !offer) {
    window.location.href = `/${channel}/error/${bookingId}/bookingerror${
      eventId ? `?eventId=${eventId}` : ''
    }`;
  }

  const hasError = isBookingError || booking.status === BOOKING_STATUS_FAILED;

  return (
    <BookingComponent
      redirectUrl={redirectUrl}
      booking={booking}
      event={event}
      isError={hasError}
      isLoading={isLoading}
      bookingId={bookingId}
      promoterSlug={channel}
    />
  );
};
