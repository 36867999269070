import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PurchaseSuccess } from '@components/PurchaseSuccess';
import { useQueryParams } from '@hooks/useQueryParams';
import { BOOKING_STATUS_FAILED, BOOKING_STATUS_PENDING } from '@models/Booking';
import PropTypes from 'prop-types';

import { Flex, Loader, TertiaryTitle } from '@xceedsrl/jukebox';

export const Booking = ({
  isLoading,
  isError,
  booking,
  event,
  bookingId,
  redirectUrl,
  promoterSlug,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQueryParams();
  const eventId = query.get('eventId');

  const redirectClientUrl = useCallback(
    (status = BOOKING_STATUS_FAILED) => {
      if (!redirectUrl || !booking) {
        return null;
      }

      const url = `${redirectUrl}?bookingId=${bookingId}&bookingStatus=${status}${
        eventId ? `&eventId=${eventId}` : ''
      }`;

      return status === BOOKING_STATUS_FAILED
        ? `${url}&failureReason=${booking.failureReason ?? null}`
        : url;
    },
    [booking]
  );

  if (isError) {
    const url = redirectClientUrl(BOOKING_STATUS_FAILED);
    if (url) {
      window.location.href = url;
    } else {
      history.push(
        `/${promoterSlug}/error/${bookingId}/${booking.status}${
          eventId ? `?eventId=${eventId}` : ''
        }`
      );
    }
  }

  if (isLoading || !booking || booking.status === BOOKING_STATUS_PENDING) {
    return (
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="75%"
        width={1}
      >
        <Loader size={250} />
        <TertiaryTitle>{t('eventPage:checkoutPendingMsg')}</TertiaryTitle>
      </Flex>
    );
  }

  return <PurchaseSuccess booking={booking} event={event} />;
};

Booking.propTypes = {
  redirectUrl: PropTypes.string,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  booking: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  bookingId: PropTypes.string.isRequired,
  promoterSlug: PropTypes.string.isRequired,
};

Booking.defaultProps = {
  isError: false,
  redirectUrl: '',
};
