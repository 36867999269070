import { animated } from '@react-spring/web';
import styled from 'styled-components';

import { Box, Col, Flex, Row } from '@xceedsrl/jukebox';

export const AnimatedRow = animated(Row);
export const AnimatedCol = animated(Col);

export const RowDivider = styled(Box)``;
RowDivider.defaultProps = {
  borderBottom: '0.5px solid',
  borderColor: 'background.mercury',
  marginY: 1,
  height: 1,
};

export const ScrollIndicator = styled(Box)`
  width: 40px;
  height: 4px;
  border-radius: 2px;
`;

export const ScrollHandler = styled(animated(Flex))`
  position: relative;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 100%;
  cursor: grab;
`;

export const BottomSheet = styled(animated.div)`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 100;
  border-radius: 36px 36px 0 0;
  box-shadow: 0 14px 36px 0 rgba(75, 80, 94, 0.03), 0 -14px 36px 0 rgba(75, 80, 94, 0.04);
  background-color: #fff;
  touch-action: none;
`;
