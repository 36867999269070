import request from '@services/apiClient';
import hosts from '@services/apiClient/hosts';

export class OfferController {
  static getOfferById({ id, channel, eventId, lang }) {
    const path = eventId ? `/v1/events/${eventId}/offers/${id}` : `/v1/admissions/${id}`;
    const queryStrings = eventId ? {} : { channel, lang };

    return request({
      path,
      baseUrl: hosts.OFFER_SERVICE,
      queryStrings,
    });
  }
}
