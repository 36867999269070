import { getTrackingAddOns } from '@utils/get-tracking-addons';

const getItems = (itemOffer, quantity, offer, addOnsSelected, channel) => {
  const items = [
    {
      ...itemOffer,
      quantity,
    },
  ];
  if (addOnsSelected)
    return [...items, ...getTrackingAddOns(addOnsSelected, offer.addOns, channel)];
  return items;
};

const formatEventData = ({
  offer,
  event,
  shoppingCart,
  quantity,
  channel,
  addOnsSelected,
  bookingId,
}) => {
  const itemOffer = {
    item_id: event.id,
    item_name: event.name,
    affiliation: channel,
    item_brand: offer.id,
    item_category: offer.admissionType,
    item_variant: offer.name,
    price: offer?.price.amount,
    currency: offer.price.currency,
    quantity,
  };

  const eventData = {
    ...(!!bookingId && { transaction_id: bookingId }),
    affiliation: channel,
    currency: offer.price.currency,
    city: event.venue.city.name,
    venue: event.venue.name,
    value: Math.trunc(shoppingCart.total * 100) / 100,
    shipping: shoppingCart.totalFee,
    items: quantity ? getItems(itemOffer, quantity, offer, addOnsSelected, channel) : itemOffer,
  };
  return eventData;
};

class GoogleTagManager {
  constructor() {
    this._trackerEnabled = false;
  }

  init() {
    if (!this._trackerEnabled) {
      this._trackerEnabled = true;
    }
  }

  removeVariables = () => {
    window.dataLayer.push({ ecommerce: undefined, user_data: undefined, data: undefined });
  };

  viewItem(props) {
    this.removeVariables();
    const ecommerce = formatEventData(props);
    window.dataLayer.push({ event: 'view_item', ecommerce });
  }

  addToCart(props) {
    this.removeVariables();
    const ecommerce = formatEventData(props);
    window.dataLayer.push({ event: 'add_to_cart', ecommerce });
  }

  beginCheckout(props) {
    this.removeVariables();
    const ecommerce = formatEventData(props);
    window.dataLayer.push({
      event: 'begin_checkout',
      ecommerce,
      ...(props?.userData, { ...props.userData }),
    });
  }

  purchase(props) {
    if (this._trackerEnabled) {
      window.dataLayer.push({ ecommerce: undefined });
      const ecommerce = formatEventData(props);
      window.dataLayer.push({
        event: 'purchase',
        ecommerce,
      });
      this._trackerEnabled = false;
    }
  }

  payment(props) {
    window.dataLayer.push({ ecommerce: undefined });
    const ecommerce = formatEventData(props);
    window.dataLayer.push({ event: 'payment', ecommerce });
  }

  addOns(props) {
    window.dataLayer.push({ ecommerce: undefined });
    const ecommerce = formatEventData(props);
    window.dataLayer.push({ event: 'add_ons', ecommerce });
  }
}

export default new GoogleTagManager();
