import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { PageWrapper } from '@components/PageWrapper';
import { useOfferEvent } from '@hooks/useOfferEvent';
import { useQueryParams } from '@hooks/useQueryParams';
import { useCheckoutActions, useCheckoutState } from '@services/checkoutClient';
import GoogleTagManager from '@services/GoogleTagManager';
import { useSharedDataActions, useSharedDataState } from '@store/sharedData';

import { AddOnsForm } from '@xceedsrl/adyenjs';

const MULTIPLE_ADDON_CHOICE = 'multiple_choice';
export const AddOns = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { channel, offerId } = useParams();
  const ref = useRef();
  const query = useQueryParams();
  const quantity = query.get('quantity');

  const redirectUrl = query.get('redirectUrl');

  const { changeQty, addProduct, addEventInfo, addRedirectUrl } = useCheckoutActions();
  const { setAddOns } = useSharedDataActions();
  const { addOns } = useSharedDataState();
  const { configuration, shoppingCart } = useCheckoutState();

  const { event, offer } = useOfferEvent({ offerId, channel, lang: i18n.language });
  const isBottleService = offer.admissionType === 'bottleservice';
  const isMultipleChoice = offer.addOnChoiceMode === MULTIPLE_ADDON_CHOICE;
  const isAddonRequired = offer.hasMandatoryAddOns;
  const translations = {
    personText: t('eventPage:addOnsPersonText'),
    bookingText: t('eventPage:addOnsBookingText'),
    headerText: t('eventPage:addOnsHeaderText'),
    headerTextSingle: t('eventPage:addOnsHeaderTextSingle'),
    optional: t('eventPage:addOnsHeaderOptional'),
    bottomText:
      addOns && addOns.length > 0
        ? ''
        : t('eventPage:addOnsBottomText', { buttonText: t('common:checkout') }),
  };

  useEffect(() => {
    if (redirectUrl) {
      addRedirectUrl(redirectUrl);
    }
  }, []);

  useEffect(() => {
    if (offer) {
      addProduct(offer, addOns);
      changeQty(quantity, offer.id, addOns);
    }
  }, [offer]);

  useEffect(() => {
    if (event) {
      addEventInfo(event);
    }
  }, [event]);

  if (!offer || !event) return null;
  const buttonText = t('common:checkout');
  const handleSubmitOutside = () => {
    if (typeof window?.dataLayer?.push === 'function')
      GoogleTagManager.addOns({
        offer,
        event,
        shoppingCart,
        channel,
        quantity,
        addOnsSelected: addOns,
      });
    const urlParamRedirectUrl = configuration.redirectUrl
      ? `?redirectUrl=${configuration.redirectUrl}&`
      : '?';

    history.push(
      `/${channel}/offer/${
        offer.id
      }/form${urlParamRedirectUrl}quantity=${quantity}&addons=${JSON.stringify(addOns)}`
    );
  };

  return (
    <PageWrapper
      isDisabled={false}
      buttonText={buttonText}
      isFormValid={(isAddonRequired && addOns.length > 0) || !isAddonRequired}
      handleSubmit={handleSubmitOutside}
    >
      <AddOnsForm
        addOns={offer.addOns}
        i18n={translations}
        lang={i18n.language}
        ticketQuantity={quantity}
        ref={ref}
        addOnsSelected={addOns ?? []}
        setAddOnsSelected={setAddOns}
        isBottleService={isBottleService}
        isRequired={isAddonRequired}
        isMultipleChoice={isMultipleChoice}
      />
    </PageWrapper>
  );
};
