import './i18n';

import React from 'react';
import { debugContextDevtool } from 'react-context-devtool';
import ReactDOM from 'react-dom';

import { App } from './app';

const container = document.getElementById('root');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  container
);
debugContextDevtool(container, {
  disable: process.env.REACT_APP_PUBLIC_PROJECT_ENV === 'production',
});
