import { Club } from '@models/Club';

import hosts from '../hosts';
import request from '..';

export class ClubController {
  static getInfo = async ({ id, slug, channel, lang }) => {
    const club = await request({
      path: `/v1/clubs/${id || slug}`,
      baseUrl: hosts.EVENTS_SERVICE,
      headers: { 'Accept-Language': lang },
      queryStrings: {
        channel,
      },
    });
    return new Club(club);
  };
}
