import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQueryParams } from '@hooks/useQueryParams';
import { useSharedDataActions, useSharedDataState } from '@store/sharedData';
import PropTypes from 'prop-types';

import {
  Button,
  Checkmark,
  Cross,
  Flex,
  FullWidthContainer,
  Icon,
  Text,
  Title,
} from '@xceedsrl/jukebox';

import { IconContainer } from '../sections/styledComponents';

export const Error = ({ channel, offerId }) => {
  const { t } = useTranslation();
  const query = useQueryParams();
  const eventId = query.get('eventId');
  const { errorType = 'default' } = useParams();
  const { adyenCheckout } = useSharedDataState();
  const { setAdyenCheckout } = useSharedDataActions();
  const isFailed = errorType !== 'successful';
  const isButtonDisabled = errorType === 'bookingerror' || errorType === 'offerNotExist';
  let subtitleError = t('eventPage:checkoutErrorDescription');
  if (errorType === 'reloadPaymentErrorDescription')
    subtitleError = t('eventPage:reloadPaymentErrorDescription');
  if (errorType === 'offerIsNotSale') subtitleError = t('eventPage:offerIsNotSale');
  if (errorType === 'offerNotExist') subtitleError = t('eventPage:offerNotExistErrorDescription');

  const handleButtonClick = event => {
    event.preventDefault();

    window.location.href = `/${channel}/offer/${offerId}${eventId ? `?eventId=${eventId}` : ''}`;
  };

  useEffect(() => {
    if (adyenCheckout) {
      adyenCheckout.unmount();
      setAdyenCheckout(null);
    }
  }, [adyenCheckout]);

  return (
    <FullWidthContainer>
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        paddingX={3}
        height={['calc(100vh - 240px)', 'calc(100vh - 136px)']}
      >
        <IconContainer bg={isFailed ? 'feeling.negative' : 'feeling.positive'}>
          <Icon fontSize={isFailed ? 10 : 12} color="brandLight">
            {isFailed ? <Cross /> : <Checkmark />}
          </Icon>
        </IconContainer>

        {!isFailed && <Title textAlign="center">{t('common:bookingAlreadyProcessed')}</Title>}

        {isFailed && (
          <>
            <Title mb={3} textAlign="center" data-testid="purchase-error--title">
              {errorType === 'offerNotExist'
                ? t('eventPage:offerNotExistErrorTitle')
                : t('eventPage:checkoutErrorTitle')}
            </Title>

            <Text fontSize={6} textAlign="center">
              {errorType === 'bookingerror' ? t('bookingErrorStatus') : subtitleError}
            </Text>

            {!isButtonDisabled && (
              <Button
                id="checkout-btn"
                variant="primary"
                onClick={handleButtonClick}
                width="auto"
                height={48}
                mt={[6, 8]}
                px="80px"
              >
                {t('common:tryAgain')}
              </Button>
            )}
          </>
        )}
      </Flex>
    </FullWidthContainer>
  );
};

Error.propTypes = {
  offerId: PropTypes.string.isRequired,
  channel: PropTypes.string.isRequired,
};
