import { useImperativeHandle, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Checkbox } from '@components/Checkbox';
import { useQueryParams } from '@hooks/useQueryParams';
import { BOOKING_STATUS_PENDING } from '@models/Booking';
import { useCheckoutActions, useCheckoutState } from '@services/checkoutClient';
import { useSharedDataActions, useSharedDataState } from '@store/sharedData';
import { getLocaleFromLang } from '@utils/get-locale-from-lang';
import PropTypes from 'prop-types';

import { usePaymentForm } from '@xceedsrl/adyenjs';
import { SecondaryText } from '@xceedsrl/jukebox';

import { LIO_GROUP_IDS, PACHA_UNIVERSE_IDS } from '../constants';

import LegalTerms from './LegalTerms';

export const Payment = ({
  bookingId,
  channel,
  booking,
  offer,
  event,
  club,
  hasToPay,
  setValid,
  forRef,
  shoppingCart,
}) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { addPaymentResult } = useCheckoutActions();
  const { participants } = useSharedDataState();
  const { configuration } = useCheckoutState();
  const { setAdyenCheckout } = useSharedDataActions();
  const [areMarketingAccepted, setAreMarketingAccepted] = useState(false);
  const query = useQueryParams();
  const offerId = query.get('offerId');
  const eventId = query.get('eventId');

  const onFailure = error => {
    history.push(
      `/${channel}/error/${offer?.id ?? offerId}/${error ? error.success : 'paymenterror'}${
        eventId ? `?eventId=${eventId}` : ''
      }`
    );
  };

  function onFailureLogging(error) {
    if (error.name.toLowerCase() === 'cancel' || error === null) onFailure(error);
  }

  const onSuccess = (response, bookingIdResp) => {
    if (response.resultCode === 'Refused') {
      onFailure(null);
      return;
    }
    addPaymentResult({ bookingId: bookingIdResp });

    const urlParamRedirectUrl = configuration.redirectUrl
      ? `redirectUrl=${configuration.redirectUrl}`
      : '';
    history.push(
      `/${channel}/booking/${bookingId}?${urlParamRedirectUrl}${
        eventId ? `&eventId=${eventId}` : ''
      }`
    );
  };

  const lang = i18n.language;
  const locale = getLocaleFromLang(lang);

  const bookingData = {
    rsvp: offer.id,
    type: offer.admissionType,
    source: 'web',
    numTickets: booking.bookingQuantity,
    price: shoppingCart.total,
    fee: offer.commissions.transactionFee,
    eventId: event.legacyId,
    venueId: event.venue.legacyId,
    venueName: event.venue.name,
    promoterSlug: channel || 'xceed-web',
    attendesForPurchase: offer.guestCapacity
      ? offer.guestCapacity * booking.bookingQuantity
      : booking.bookingQuantity,
    bookingHoldToken: null,
    bookingId,
  };

  const [PaymentForm, submit] = usePaymentForm({
    config: {
      apiHost: `${process.env.REACT_APP_PUBLIC_LEGACY_API_HOST}/b2c/v4`,
      checkoutHost: process.env.REACT_APP_PUBLIC_PAYMENTS_SERVICE_HOST,
      clientKey: process.env.REACT_APP_ADYEN_CLIENT_KEY,
      environment: process.env.REACT_APP_ADYEN_ENVIRONMENT,
    },
    paymentOptions: {
      country: club.city?.country.isoCode,
      amount: shoppingCart.total,
      hasToPay,
      currency: offer.price.currency,
      storePaymentMethod: false,
      paymentAccountId: null,
      googlePayConfiguration: {
        merchantName: process.env.REACT_APP_GOOGLE_PAY_MERCHANT_NAME,
        gatewayMerchantId: process.env.REACT_APP_GOOGLE_PAY_GATEWAY_MERCHANT_ID,
        merchantId: process.env.REACT_APP_GOOGLE_PAY_MERCHANT_ID,
      },
    },
    checkoutOptions: { locale },
    dropinProps: {
      lang,
      bookingData,
      onSuccess,
      onFailure,
      onFailureLogging,
      onValidate: setValid,
      is18appEnabled: !!offer.settings?.italyRegulation?.allows18App,
    },
    participants,
    setAdyenCheckout,
  });

  useImperativeHandle(forRef, () => ({
    handleSubmitFromParent() {
      submit(areMarketingAccepted ? { marketingConditionsAccepted: true } : undefined);
    },
  }));

  if (booking.status !== BOOKING_STATUS_PENDING) {
    history.push(`/${channel}/error/${offer.id}/${booking.status}`);
  }

  const handleMarketingAccepted = e => {
    const { checked } = e.target;
    setAreMarketingAccepted(checked);
  };

  const createMarketingTerms = ({ translationKey, linkGroup, linkPrivacy }) => (
    <Trans
      i18nKey={`common:${translationKey}`}
      components={{
        linkGroup: (
          <a href={linkGroup} target="_blank" rel="noreferrer" style={{ color: 'black' }}>
            group
          </a>
        ),
        linkPrivacy: (
          <a href={linkPrivacy} target="_blank" rel="noreferrer" style={{ color: 'black' }}>
            privacy
          </a>
        ),
        bold: <strong />,
      }}
    />
  );

  let alternativeTerms = PACHA_UNIVERSE_IDS.includes(club.id)
    ? createMarketingTerms({
        translationKey: 'marketingCheckboxPacha',
        linkGroup: process.env.REACT_APP_PACHA_UNIVERSE_LINK_GROUP,
        linkPrivacy: process.env.REACT_APP_PACHA_UNIVERSE_LINK_PRIVACY,
      })
    : null;

  alternativeTerms = LIO_GROUP_IDS.includes(club.id)
    ? createMarketingTerms({
        translationKey: 'marketingCheckboxLio',
        linkPrivacy: process.env.REACT_APP_LIO_GROUP_LINK_PRIVACY,
        linkGroup: process.env.REACT_APP_LIO_GROUP_LINK_GROUP,
      })
    : alternativeTerms;

  return (
    <>
      <form>{PaymentForm}</form>
      <LegalTerms company={event.company} buttonText={t('common:pay')} />
      <Checkbox
        id="policy"
        name="policy"
        onChange={handleMarketingAccepted}
        checked={areMarketingAccepted}
        childrenPaddingTop="0"
        childrenMarginLeft={alternativeTerms ? '24px' : '2'}
        boxMarginBottom={50}
        boxMarginTop={10}
        alignTop={!!alternativeTerms}
      >
        <SecondaryText>
          {alternativeTerms ?? (
            <Trans
              i18nKey="common:marketingCheckboxLabel" // optional -> fallbacks to defaults if not provided
              values={{ companyCommercialName: event.venue.name }}
              components={{ bold: <strong /> }}
            />
          )}
        </SecondaryText>
      </Checkbox>
    </>
  );
};

Payment.propTypes = {
  booking: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  club: PropTypes.object.isRequired,
  bookingId: PropTypes.string.isRequired,
  channel: PropTypes.string.isRequired,
  hasToPay: PropTypes.bool.isRequired,
  setValid: PropTypes.func.isRequired,
  forRef: PropTypes.any.isRequired,
  shoppingCart: PropTypes.object.isRequired,
};
