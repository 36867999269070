import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Form as FormComponent } from '@components/Form';
import { useOfferEvent } from '@hooks/useOfferEvent';
import { useQueryParams } from '@hooks/useQueryParams';
import { useCheckoutActions } from '@services/checkoutClient';
import { useSharedDataActions, useSharedDataState } from '@store/sharedData';

export const Form = () => {
  const { i18n } = useTranslation();
  const { channel, offerId } = useParams();

  const query = useQueryParams();
  const addOnsQuery = query.get('addons');
  const firstName = query.get('firstName');
  const lastName = query.get('lastName');
  const email = query.get('email');
  const phoneQuery = query.get('phone');
  const phone = phoneQuery ? `+${phoneQuery}` : null;
  const quantity = query.get('quantity');
  const redirectUrl = query.get('redirectUrl');
  const eventId = query.get('eventId');
  const { addOns } = useSharedDataState();

  const { event, offer } = useOfferEvent({ offerId, channel, eventId, lang: i18n.language });

  const { changeQty, addProduct, addEventInfo, addRedirectUrl, addPassInfo } = useCheckoutActions();

  const { setAddOns } = useSharedDataActions();

  useEffect(() => {
    if (redirectUrl) {
      addRedirectUrl(redirectUrl);
    }
  }, []);

  useEffect(() => {
    if (offer) {
      addProduct(offer, addOns);
      changeQty(quantity, offer.id, addOns);
    }
  }, [offer]);

  useEffect(() => {
    if (event) {
      addEventInfo(event);
    }
  }, [event]);

  useEffect(() => {
    setAddOns(JSON.parse(addOnsQuery));
  }, [addOnsQuery]);

  useEffect(() => {
    if (eventId) {
      addPassInfo(eventId);
    }
  }, [eventId]);

  if (!offer || !event) {
    return null;
  }

  return (
    <FormComponent
      channel={channel}
      initialValues={{
        firstName,
        lastName,
        email,
        phone,
      }}
    />
  );
};
