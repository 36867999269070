import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Club } from '@models/Club';
import { ClubController } from '@services/apiClient/interfaces/ClubController';
import { useChannelState } from '@store/channel';

export function useClub({ id, initialData }) {
  const {
    i18n: { language },
  } = useTranslation();
  const channel = useChannelState();

  const clubQuery = ['club', { id, language, channel }];
  const {
    data,
    isLoading: isLoadingInfo,
    isSuccess: isSuccessInfo,
  } = useQuery(clubQuery, () => ClubController.getInfo({ id, lang: language, channel }), {
    staleTime: Infinity,
    initialData: initialData?.clubInfo,
  });

  const isLoading = isLoadingInfo;
  const isSuccess = isSuccessInfo;

  const club = useMemo(
    () =>
      data &&
      new Club({
        ...data,
      }),
    [data]
  );

  return {
    club,
    isLoading,
    isSuccess,
  };
}
