import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { RsvpDetails as RsvpDetailsComponent } from '@components/RsvpDetails';
import { useOfferEvent } from '@hooks/useOfferEvent';
import { useQueryParams } from '@hooks/useQueryParams';
import { useCheckoutActions, useCheckoutState } from '@services/checkoutClient';
import { useSharedDataActions } from '@store/sharedData';

export const RsvpDetails = () => {
  const { channel, offerId } = useParams();
  const query = useQueryParams();
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const redirectUrl = query.get('redirectUrl');
  const eventId = query.get('eventId');

  const { event, offer } = useOfferEvent({ offerId, channel, eventId, lang });
  const { changeQty, addProduct, addEventInfo, addRedirectUrl, addPassInfo } = useCheckoutActions();
  const { shoppingCart } = useCheckoutState();
  const { setAddOns } = useSharedDataActions();

  useEffect(() => {
    setAddOns([]);
  }, [setAddOns]);

  useEffect(() => {
    if (offer) {
      addProduct(offer);
    }
  }, [offer]);

  useEffect(() => {
    if (redirectUrl) {
      addRedirectUrl(redirectUrl);
    }
  }, []);

  useEffect(() => {
    if (event) {
      addEventInfo(event);
    }
  }, [event]);

  useEffect(() => {
    if (eventId) {
      addPassInfo(eventId);
    }
  }, [eventId]);

  if (!event || !offer) {
    return null;
  }

  return (
    <RsvpDetailsComponent
      channel={channel}
      event={event}
      offer={offer}
      changeQty={changeQty}
      shoppingCart={shoppingCart}
    />
  );
};
