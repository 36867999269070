import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { PageWrapper } from '@components/PageWrapper';
import { Payment as PaymentComponent } from '@components/Payment';
import { useBookingData } from '@hooks/useBookingData';
import { useQueryParams } from '@hooks/useQueryParams';
import { BOOKING_STATUS_PENDING } from '@models/Booking';
import { useCheckoutActions, useCheckoutState } from '@services/checkoutClient';
import GoogleTagManager from '@services/GoogleTagManager';
import { useSharedDataState } from '@store/sharedData';
import { isOfferBuyable } from '@utils/is-offer-buyable';

export const Payment = () => {
  const history = useHistory();
  const childRef = useRef();
  const { t, i18n } = useTranslation();
  const { bookingId, channel } = useParams();
  const query = useQueryParams();
  const redirectUrl = query.get('redirectUrl');
  const eventId = query.get('eventId');
  const paymentId = query.get('paymentId');
  const sessionId = query.get('sessionId');
  const redirectResult = query.get('redirectResult');

  const { event, offer, booking, club } = useBookingData({
    bookingId,
    channel,
    eventId,
    lang: i18n.language,
  });
  const { addProductWithQuantity, addEventInfo, addRedirectUrl, addPassInfo } =
    useCheckoutActions();
  const { shoppingCart } = useCheckoutState();
  const [isFormValid, setIsFormValid] = useState(true);
  const [isPayButtonClicked, setIsPayButtonClicked] = useState(false);
  const { addOns } = useSharedDataState();

  useEffect(() => {
    if (redirectUrl) {
      addRedirectUrl(redirectUrl);
    }
  }, []);

  useEffect(() => {
    if (eventId) {
      addPassInfo(eventId);
    }
  }, [eventId]);

  useEffect(() => {
    if (offer && booking) {
      addProductWithQuantity({ ...offer, quantity: booking.quantity }, addOns);
    }
  }, [offer, booking]);

  useEffect(() => {
    if (event) {
      addEventInfo(event);
    }
  }, [event]);

  // When a CC has 3DS 1, Adyen returns the user back to our app with query parameters in order to handle if the payment was or not successful
  useEffect(() => {
    if (redirectResult && paymentId && sessionId) {
      // TODO: abstract routes
      history.push(
        `/${channel}/confirm/${bookingId}?paymentId=${paymentId}&sessionId=${sessionId}&redirectResult=${redirectResult}${
          eventId ? `&eventId=${eventId}` : ''
        }`
      );
    }
  }, [redirectResult, paymentId, sessionId]);

  const handleSubmitOutside = () => {
    if (!booking) {
      return;
    }
    setIsPayButtonClicked(true);
    if (typeof window?.dataLayer?.push === 'function')
      GoogleTagManager.payment({
        offer,
        event,
        shoppingCart,
        channel,
        quantity: booking.quantity,
        addOnsSelected: addOns,
      });

    if (booking.status === BOOKING_STATUS_PENDING) {
      if (childRef?.current) {
        childRef.current.handleSubmitFromParent();
      }
      return;
    }
    history.push(`/${channel}/booking/${bookingId}${eventId ? `?eventId=${eventId}` : ''}`);
  };

  const handleSetIsValid = useCallback(
    isValid => {
      setIsFormValid(isValid);
    },
    [setIsFormValid]
  );

  if (!event || !offer || !booking || !club || !shoppingCart) {
    return null;
  }

  const hasToPay = shoppingCart.total > 0 && offer?.onlinePaymentPercentage > 0;

  const isDisabled = !isOfferBuyable(offer);

  return (
    <PageWrapper
      buttonText={t('common:pay')}
      isFormValid={isFormValid}
      handleSubmit={handleSubmitOutside}
      isDisabled={isDisabled}
      isPayButtonClicked={isPayButtonClicked}
    >
      <PaymentComponent
        shoppingCart={shoppingCart}
        forRef={childRef}
        setValid={handleSetIsValid}
        hasToPay={hasToPay}
        club={club}
        bookingId={bookingId}
        channel={channel}
        booking={booking}
        offer={offer}
        event={event}
      />
    </PageWrapper>
  );
};
