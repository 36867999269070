import { Country } from '@models/Country';

const defaultCity = {
  id: null,
  legacyId: null,
  name: null,
  slug: null,
  coverUrl: null,
  currency: null,
  timezone: null,
  coordinates: {},
  country: new Country(),
  settings: {
    showArtists: false,
    showBars: false,
    showClubs: false,
    showEvents: false,
    showRestaurants: false,
  },
  guide: [],
};

export class City {
  constructor(city = defaultCity) {
    Object.assign(this, City.parse({ ...defaultCity, ...city }));
  }

  static parse(city) {
    const { country } = city;
    return { ...city, country: new Country(country) };
  }
}
