import React from 'react';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';

const FallbackComponent = () => <div>An error has occurred</div>;

export const ErrorBoundary = ({ children }) => (
  <Sentry.ErrorBoundary fallback={FallbackComponent}>{children}</Sentry.ErrorBoundary>
);

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};
