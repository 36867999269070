import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { EventController } from '@services/apiClient/interfaces/EventController';
import { OfferController } from '@services/apiClient/interfaces/OfferController';

export const useOfferEvent = ({ offerId, channel, eventId, lang }) => {
  const history = useHistory();

  const {
    data: offer,
    isLoading: isLoadingOffer,
    isSuccess: isOfferSuccess,
  } = useQuery(
    ['offer-id', { offerId }],
    () => OfferController.getOfferById({ id: offerId, channel, eventId, lang }),
    {
      onError: () => history.push(`/${channel}/error/${offerId}/offerNotExist`),
    }
  );

  const {
    data: event,
    isLoading: isLoadingInfo,
    isSuccess: isInfoSuccess,
  } = useQuery(
    ['event-id', { id: offer?.id, channel }],
    () => EventController.getInfo({ id: eventId ?? offer?.event?.id, channel }),
    {
      enabled: !!eventId || !!offer,
      staleTime: Infinity,
    }
  );

  const isLoading = isLoadingInfo || isLoadingOffer;
  const isSuccess = isInfoSuccess || isOfferSuccess;

  return {
    event,
    offer,
    isLoading,
    isSuccess,
  };
};
