/* eslint-disable no-use-before-define */
import React, { useContext, useMemo, useReducer } from 'react';

import { getActions } from './actions';
import { initialState, reducer } from './reducer';

const CheckoutContextState = React.createContext({
  initialState,
});
const CheckoutContextActions = React.createContext({});

CheckoutContextState.displayName = 'CheckoutContextState';
CheckoutContextActions.displayName = 'CheckoutContextActions';

export const useCheckoutState = () => useContext(CheckoutContextState);
export const useCheckoutActions = () => useContext(CheckoutContextActions);

export function CheckoutProvider(props) {
  const [checkoutState, dispatch] = useReducer(reducer, initialState);

  const actions = useMemo(() => getActions(dispatch), [dispatch]);

  return (
    <CheckoutContextActions.Provider value={actions}>
      <CheckoutContextState.Provider value={checkoutState} {...props} />
    </CheckoutContextActions.Provider>
  );
}
