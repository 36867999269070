/* eslint-disable react/prop-types */
/* stylelint-disable unit-no-unknown */
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDrag } from 'react-use-gesture';
import { useChannel } from '@hooks/useChannel';
import { animated, config, useSpring } from '@react-spring/web';
import { useCheckoutActions, useCheckoutState } from '@services/checkoutClient';
import { useSharedDataState } from '@store/sharedData';
import { formatPrice } from '@utils/format-price';
import { getLanguageTranslation } from '@utils/getLanguageTranslation';

import {
  Alert,
  Box,
  Button,
  ChevronDown,
  Col,
  Collapse,
  Flex,
  Icon,
  Row,
  SecondaryTitle,
  TertiaryTitle,
  Text,
  useToggle,
} from '@xceedsrl/jukebox';

import { EventController } from '../../services/apiClient/interfaces/EventController';

import {
  AnimatedCol,
  AnimatedRow,
  BottomSheet,
  RowDivider,
  ScrollHandler,
  ScrollIndicator,
} from './styledComponents';

const RotationCol = animated(Col);

export function Sidebar(props) {
  return (
    <Box height="min-content" position="sticky" top={0} {...props} border="1px">
      <ShoppingCart />
    </Box>
  );
}

export const ShoppingCart = ({ isSummary = false }) => {
  const {
    t,
    locale,
    i18n: { language },
  } = useTranslation();
  const refCollapsed = useRef();
  const [numSelectedAddons, setNumSelectedAddons] = useState(0);
  const { shoppingCart, event } = useCheckoutState();
  const [isCollapsed, toggleExpansion] = useToggle(true);
  const { updateCostsWithAddons } = useCheckoutActions();

  const { addOns } = useSharedDataState();
  const { channel = 'xceed-web' } = useParams();

  const { channel: channelInfo, isSuccess: isSuccessChannelInfo } = useChannel({
    channelSlug: channel,
  });

  const { products, selectedOffer } = shoppingCart;
  const { settings, price, bookingQuantity } = products[selectedOffer] || {};
  const { currency, amount } = price;

  useEffect(() => {
    const addOnsLength = addOns?.length ?? 0;

    if (addOnsLength > numSelectedAddons && isCollapsed) toggleExpansion(false);
    if (addOnsLength < numSelectedAddons && !isCollapsed) toggleExpansion(true);

    setNumSelectedAddons(addOnsLength);
  }, [addOns]);

  useEffect(() => {
    if (isSuccessChannelInfo && !!event.id && !!channelInfo?.id)
      EventController.registerPageView(event.id, channelInfo?.id);
  }, [isSuccessChannelInfo, channelInfo, event]);

  const content = useMemo(
    () => ({
      fee: t('eventPage:checkoutCartFee'),
      free: t('eventPage:free'),
      total: t('eventPage:checkoutCartTotal'),
      summary: t('eventPage:checkoutCartSummary'),
      subtotal: t('eventPage:checkoutCartSubtotal'),
      totalDueNow: t('eventPage:checkoutCartDueNow'),
      downPayment: t('eventPage:checkoutDownpayment'),
      checkoutCartPayThen: t('eventPage:checkoutCartPayThen', {
        amount: formatPrice(shoppingCart.subTotal + shoppingCart.totalFee, currency, locale),
      }),
    }),
    [language, shoppingCart, currency]
  );

  const iconSpring = useSpring({
    transform: isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)',
  });

  useEffect(() => {
    if (addOns) {
      updateCostsWithAddons(addOns, shoppingCart.selectedOffer);
    }
  }, [addOns]);

  return (
    <Box width={1} paddingTop={[0, 2]}>
      <Box width={1} flexDirection="column">
        {!isSummary && <SecondaryTitle pb="32px">{content.summary}</SecondaryTitle>}
        {Object.values(shoppingCart.products).map(product => (
          <Fragment key={product.id}>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              padding={2}
              backgroundColor="white"
              borderRadius="6px"
              boxShadow="0 14px 36px 0 rgba(75, 80, 94, 0.06)"
              onClick={toggleExpansion}
              style={{ cursor: 'pointer' }}
            >
              <Text fontWeight="900" wrapped>{`${product.bookingQuantity} x ${
                getLanguageTranslation(
                  product.name,
                  language,
                  product.admissionType ?? product.passType.replace('_', ''),
                  t
                ) || product.typeText
              }`}</Text>

              <RotationCol style={iconSpring} width="min-content">
                <Icon fontSize={1}>
                  <ChevronDown />
                </Icon>
              </RotationCol>
            </Flex>
            <Collapse ref={refCollapsed} isCollapsed={isCollapsed} collapsedHeight={0}>
              <Box paddingX={2} pt={2}>
                <ShoppingCartItem
                  key={product.id}
                  text={`${product.bookingQuantity} x ${
                    getLanguageTranslation(
                      product.name,
                      language,
                      product.admissionType ?? product.passType.replace('_', ''),
                      t
                    ) || product.typeText
                  }`}
                  value={amount ? formatPrice(amount, currency, locale) : content.free}
                />
                {addOns?.map(addOn => (
                  <ShoppingCartItem
                    key={addOn.id}
                    text={`${addOn.quantity} x ${addOn.name}`}
                    value={formatPrice(addOn.price, currency, locale)}
                  />
                ))}
                <RowDivider />
              </Box>
            </Collapse>
          </Fragment>
        ))}
        <Box paddingX={2} pt={2}>
          <ShoppingCartItem
            text={content.fee}
            value={
              shoppingCart.totalFee ? formatPrice(shoppingCart.totalFee, currency, locale) : '-'
            }
          />
          {!!price.offlinePrice && price.offlinePrice > 0 && (
            <Flex justifyContent="space-between" paddingY={1}>
              <Text fontWeight="900" color="#fbb86d">
                {content.downPayment}
              </Text>
              <Text fontWeight="900" color="#fbb86d">
                - {formatPrice(price.offlinePrice * bookingQuantity, currency, locale)}
              </Text>
            </Flex>
          )}
          {shoppingCart.totalDDP > 0 && (
            <ShoppingCartItem
              text="DDP"
              value={formatPrice(shoppingCart.totalDDP, currency, locale)}
            />
          )}
          <Flex pb={1} justifyContent="space-between">
            <TertiaryTitle>{isSummary ? content.total : content.subtotal}</TertiaryTitle>
            <TertiaryTitle>{formatPrice(shoppingCart.total, currency, locale)}</TertiaryTitle>
          </Flex>
        </Box>
      </Box>
      <Col paddingY={1}>
        {settings?.isPayAtDoor && amount > 0 && (
          <Flex width={1} justifyContent="flex-end">
            <Alert color="feeling.positive">{content.checkoutCartPayThen}</Alert>
          </Flex>
        )}
      </Col>
    </Box>
  );
};

const ShoppingCartItem = React.memo(({ text, value }) => (
  <Flex justifyContent="space-between" paddingY={1} style={{ gap: '1rem' }}>
    <Text wrapped>{text}</Text>
    <Text>{value}</Text>
  </Flex>
));

export function ExpansiveSummary({
  onSubmit,
  buttonText,
  isFormValid,
  isDisabled,
  isPayButtonClicked,
}) {
  const { t, locale } = useTranslation();
  const { shoppingCart } = useCheckoutState();
  const { addOns } = useSharedDataState();

  const { products, selectedOffer } = shoppingCart;
  const { settings, price } = products[selectedOffer] || {};
  const { amount, currency } = price;
  const closedHeight = 132;
  const openedHeight =
    346 +
    (shoppingCart.totalDDP && 36) +
    (settings.isPayAtDoor && amount > 0 ? 24 : 0) +
    (!!addOns && addOns?.length * 40);

  const scrollTrigger = 30;

  const [isOpen, setOpen] = useState(false);

  const draggingRef = useRef(false);

  const [height, set] = useState(closedHeight);
  const sheetSpring = useSpring({
    height,
    config: config.stiff,
  });

  const shoppingCartAnimation = {
    overflowY: 'hidden',
    height: sheetSpring.height
      .to({
        range: [closedHeight, openedHeight],
        output: [0, openedHeight - closedHeight + 24],
      })
      .to(o => o),
    opacity: sheetSpring.height
      .to({
        range: [closedHeight, openedHeight],
        output: [0, 1],
      })
      .to(o => o),
  };

  const ctaAnimation = {
    width: sheetSpring.height
      .to({ range: [closedHeight, openedHeight], output: [(7 / 12) * 100, 100] })
      .to(w => `${w}%`),
  };

  const summaryAnimation = {
    opacity: sheetSpring.height
      .to({
        range: [closedHeight, openedHeight],
        output: [1, 0],
      })
      .to(o => o),
    transform: sheetSpring.height
      .to({ range: [closedHeight, openedHeight], output: [0, 100] })
      .to(w => `translateX(${-w}%)`),
  };

  const open = () => {
    setOpen(true);
    set(openedHeight);
  };

  const close = () => {
    setOpen(false);
    set(closedHeight);
  };

  const handleButtonClick = useCallback(() => {
    close();
    onSubmit();
  }, [onSubmit]);

  const toggle = () => (isOpen ? close() : open());

  const bind = useDrag(
    ({ last, vxvy: [, vy], movement: [, my], cancel, delta: [, dy] }) => {
      const isFast = vy > 0.5;
      const isScrollingUp = my < -scrollTrigger;
      const isScrollingDown = my > scrollTrigger;

      if (isOpen && isScrollingUp) cancel();
      if (!isOpen && isScrollingDown) cancel();
      if (last) {
        if (isOpen) {
          // eslint-disable-next-line no-unused-expressions
          isScrollingDown || isFast ? close() : open();
        } else {
          // eslint-disable-next-line no-unused-expressions
          isScrollingUp || isFast ? open() : close();
        }
      } else {
        let newHeight = height - dy;
        if (newHeight > openedHeight) newHeight = openedHeight;
        if (newHeight < closedHeight) newHeight = closedHeight;
        set(newHeight);
      }
    },
    { axis: 'y' }
  );

  return (
    <Box position="fixed" bottom={0} width="100%">
      <Box position="relative" height={closedHeight} bottom={0}>
        <BottomSheet style={sheetSpring}>
          <ScrollHandler {...bind()} onClick={toggle} ref={draggingRef}>
            <ScrollIndicator backgroundColor="accent.text" />
          </ScrollHandler>
          <Box position="absolute" bottom={0} paddingX={3} paddingBottom={3} width={1}>
            <AnimatedRow style={shoppingCartAnimation}>
              <Col width={1} pb={3}>
                <ShoppingCart isSummary />
              </Col>
            </AnimatedRow>
            <Row position="relative" flexDirection="row-reverse" backgroundColor="brandLight">
              <AnimatedCol style={ctaAnimation}>
                <Button
                  id="checkout-btn"
                  variant="primary"
                  disabled={!isFormValid || isDisabled || isPayButtonClicked}
                  onClick={handleButtonClick}
                  height={48}
                  lineHeight={1}
                >
                  {buttonText}
                </Button>
              </AnimatedCol>
              <AnimatedCol
                style={summaryAnimation}
                width="auto"
                position="absolute"
                left={0}
                bottom={0}
              >
                <Alert color="accent.text" onClick={toggle} style={{ cursor: 'pointer' }}>
                  {t('eventPage:checkoutCartSeePriceBreakdown')}
                </Alert>
                <Text>{t('eventPage:checkoutCartTotal')}</Text>
                <TertiaryTitle as="div">
                  {formatPrice(shoppingCart.total, currency, locale)}
                </TertiaryTitle>
              </AnimatedCol>
            </Row>
          </Box>
        </BottomSheet>
      </Box>
    </Box>
  );
}
