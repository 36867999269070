export const BOOKING_DATA_SOURCE = 'widget';

export const ALL_LOCALES = ['ca-ES', 'de-DE', 'en-US', 'es-ES', 'fr-FR', 'it-IT', 'pt-PT'];

export const TILL_CLOSING_CLUB_IDS = [
  '88696596-85c0-4b95-b4c5-b5c79044f39f',
  '1abb4cdc-dd17-499e-b73f-a0a923c659fe',
];

export const PACHA_UNIVERSE_IDS = process.env.REACT_APP_PACHA_UNIVERSE_IDS.split(',');

export const LIO_GROUP_IDS = process.env.REACT_APP_LIO_GROUP_IDS.split(',');

export const SOLD_OUT_STATUS = 'sold_out';

export const SALES_CLOSED_STATUS = 'sales_closed';
