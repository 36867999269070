import { useCallback, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Checkbox } from '@components/Checkbox';
import { PageWrapper } from '@components/PageWrapper';
import { useQueryParams } from '@hooks/useQueryParams';
import { BookingController } from '@services/apiClient/interfaces/BookingController';
import { useCheckoutState } from '@services/checkoutClient';
import GoogleTagManager from '@services/GoogleTagManager';
import { useSharedDataActions, useSharedDataState } from '@store/sharedData';
import { getFormatedPixelUserData } from '@utils/get-formatted-pixel-user-data';
import { isOfferBuyable } from '@utils/is-offer-buyable';
import PropTypes from 'prop-types';

import { ParticipantsForm } from '@xceedsrl/adyenjs';
import { getGTMUserData, SecondaryText } from '@xceedsrl/jukebox';

import { BOOKING_DATA_SOURCE } from '../constants';

import LegalTerms from './LegalTerms';

export const Form = ({ channel, initialValues }) => {
  const history = useHistory();
  const query = useQueryParams();
  const quantityUrl = query.get('quantity');
  const { mutateAsync: executeBookingAttempt } = useMutation(BookingController.bookingAttempt);
  const { t, i18n } = useTranslation();
  const formRef = useRef();
  const { shoppingCart, event, configuration } = useCheckoutState();
  const { addParticipants, setAdyenCheckout } = useSharedDataActions();
  const [isFormValid, setIsFormValid] = useState(true);
  const [isAllValid, setIsAllValid] = useState(false);
  const [arePoliciesAccepted, setArePoliciesAccepted] = useState(false);
  const [areMarketingAccepted, setAreMarketingAccepted] = useState(false);
  const { bookingHoldToken, adyenCheckout, addOns, participants } = useSharedDataState();

  const handleSubmitOutside = useCallback(() => {
    setIsAllValid(false);
    formRef.current.click();
  }, [formRef]);

  const handleSetValid = useCallback(
    (isValid, values) => {
      if (isValid) addParticipants(values);
      setIsFormValid(isValid);
    },
    [setIsFormValid]
  );

  useEffect(() => {
    if (adyenCheckout) {
      adyenCheckout.unmount();
      setAdyenCheckout(null);
    }
  }, [adyenCheckout]);

  const offer = shoppingCart.products[shoppingCart.selectedOffer];

  const quantity = parseInt(quantityUrl, 10);
  const attendesForPurchase = offer.guestCapacity ? offer.guestCapacity * quantity : quantity;

  const hasToPay = shoppingCart.total > 0 && offer.onlinePaymentPercentage > 0;
  const buttonText = hasToPay ? t('common:checkout') : t('eventPage:ctaBookNow');

  const getRedirecLink = (bookingId, offerId) => {
    if (!hasToPay) {
      return `/${channel}/booking/${bookingId}${
        configuration.passInfo ? `?eventId=${configuration.passInfo}` : ''
      }`;
    }

    const paymentLink = `/${channel}/payment/${bookingId}?offerId=${offerId}${
      configuration.passInfo ? `&eventId=${configuration.passInfo}` : ''
    }`;

    return configuration.redirectUrl
      ? `${paymentLink}&redirectUrl=${configuration.redirectUrl}`
      : paymentLink;
  };

  const handleSubmit = form => {
    const bookingData = {
      ...form,
      attendesForPurchase,
      rsvp: offer.id,
      type: offer.admissionType,
      source: BOOKING_DATA_SOURCE,
      numTickets: quantity,
      // price and fee is deprecated
      price: offer.price.amount,
      fee: offer.commissions.transactionFee,
      eventId: event.legacyId,
      venueId: event.venue.legacyId,
      venueName: event.venue.name,
      promoterSlug: channel,
      bookingHoldToken: offer?.tableManagementOfferId ? bookingHoldToken : null,
      marketingConditionsAccepted: areMarketingAccepted,
      addOns: addOns ? addOns.map(addOn => ({ id: addOn.id, quantity: addOn.quantity })) : [],
    };

    executeBookingAttempt({ lang: i18n.language, bookingData })
      .then(({ bookingId }) => {
        const redirectLink = getRedirecLink(bookingId, offer.id);
        if (typeof window?.dataLayer?.push === 'function') {
          const userData = getGTMUserData(participants);
          GoogleTagManager.beginCheckout({
            offer,
            event,
            shoppingCart,
            channel,
            quantity,
            addOnsSelected: addOns,
            userData,
          });
        }
        // eslint-disable-next-line no-undef
        if (configuration?.facebookPixel) {
          const userInit = getFormatedPixelUserData(form.bookingFormFields);
          // eslint-disable-next-line no-undef
          fbq('init', configuration?.facebookPixel, userInit);
          // eslint-disable-next-line no-undef
          fbq('track', 'InitiateCheckout', {
            value: Math.trunc(shoppingCart.total * 100) / 100,
            currency: offer?.price.currency,
            content_name: event?.name,
            content_ids: event?.id,
            content_type: `${offer?.admissionType}${offer?.name ? ` - ${offer?.name}` : ''}`,
            num_items: quantity,
            contents: [
              {
                id: offer?.id,
                quantity,
              },
              ...(addOns?.map(addOn => ({ id: addOn.id, quantity: addOn.quantity })) ?? []),
            ],
          });
        }
        history.push(redirectLink);
      })
      .catch(() => {
        history.push(
          `/${channel}/error/${offer?.id}/offerIsNotSale${
            configuration.passInfo ? `?eventId=${configuration.passInfo}` : ''
          }`
        );
      });
  };

  useEffect(() => {
    const isOfferValid = isOfferBuyable(offer);
    const isEventPolicyValid = event?.checkoutTerms ? arePoliciesAccepted : true;
    setIsAllValid(isOfferValid && isEventPolicyValid);
  }, [offer, event, arePoliciesAccepted]);

  const handlePoliciesAccepted = e => {
    const { checked } = e.target;
    setArePoliciesAccepted(checked);
  };

  const handleMarketingAccepted = e => {
    const { checked } = e.target;
    setAreMarketingAccepted(checked);
  };

  return (
    <PageWrapper
      isDisabled={!isAllValid}
      buttonText={buttonText}
      isFormValid={isFormValid}
      handleSubmit={handleSubmitOutside}
    >
      <ParticipantsForm
        ref={formRef}
        lang={i18n.language}
        initialValues={initialValues}
        buyerFields={offer.bookingFormFields}
        holderFields={offer.ticketHolderFormFields}
        onSubmit={handleSubmit}
        attendesForPurchase={attendesForPurchase}
        onValid={handleSetValid}
      />
      {!hasToPay && event && (
        <>
          <LegalTerms company={event.company} buttonText={t('eventPage:ctaBookNow')} />
          <Checkbox
            id="policy"
            name="policy"
            onChange={handleMarketingAccepted}
            checked={areMarketingAccepted}
            childrenPaddingTop="0"
            boxMarginBottom={!event?.checkoutTerms ? 50 : 0}
            boxMarginTop={10}
          >
            <SecondaryText>
              <Trans
                i18nKey="common:marketingCheckboxLabel" // optional -> fallbacks to defaults if not provided
                values={{ companyCommercialName: event.venue.name }}
                components={{ bold: <strong /> }}
              />
            </SecondaryText>
          </Checkbox>
        </>
      )}
      {!!event?.checkoutTerms && (
        <Checkbox
          id="policy"
          name="policy"
          onChange={handlePoliciesAccepted}
          checked={arePoliciesAccepted}
        >
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: event.checkoutTerms,
            }}
          />
        </Checkbox>
      )}
    </PageWrapper>
  );
};

Form.propTypes = {
  initialValues: PropTypes.object.isRequired,
  channel: PropTypes.string.isRequired,
};
