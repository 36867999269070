import moment from 'moment-timezone';

const typeFbUserData = {
  firstName: 'fn',
  lastName: 'ln',
  email: 'em',
  phone: 'ph',
  birth: 'db',
};

export const getFormatedPixelUserData = bookingAttendersData => {
  let attenders;
  bookingAttendersData.forEach(attender => {
    if (attender.name === 'firstName' || attender.name === 'lastName' || attender.name === 'email')
      attenders = {
        ...attenders,
        [typeFbUserData[attender.name]]: attender.value.trim().toLowerCase(),
      };
    if (attender.name === 'phone') {
      attenders = {
        ...attenders,
        [typeFbUserData[attender.name]]: attender.value.trim().toLowerCase().slice(1),
      };
    }
    if (attender.name === 'birth')
      attenders = {
        ...attenders,
        [typeFbUserData[attender.name]]: moment(attender.value)
          .format('YYYYMMDD')
          .replace(/\s+/g, ''),
      };
  });
  return attenders;
};
