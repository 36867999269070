import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Container } from '@components/Container';
import { useCheckoutState } from '@services/checkoutClient';
import { getLanguageTranslation } from '@utils/getLanguageTranslation';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Image, Info, Tag } from '@xceedsrl/jukebox';

import { TILL_CLOSING_CLUB_IDS } from '../constants';

import AnalyticsHeader from './AnalyticsHeader';

function isLongerThanTwelveHours(start, end) {
  const TWELVE_HOURS = 12 * 60 * 60;
  return end - start > TWELVE_HOURS;
}

const WrapperBackground = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.background.spaceWhite};
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 26px 36px;
  border: none;
  display: flex;
  flex-direction: row;

  @media ${props => props.theme.mediaQueries.mobile} {
    flex-direction: column;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 866px;
  margin-left: 16px;

  @media ${props => props.theme.mediaQueries.mobile} {
    padding-left: 0px;
    padding-top: 20px;
    margin-left: 0;
  }
`;

export const Header = ({ offer }) => {
  const { event } = useCheckoutState();

  if (!event) {
    return null;
  }

  const { channel = 'xceed-web' } = useParams();
  const { i18n, t } = useTranslation();
  const isTillClosingActive = TILL_CLOSING_CLUB_IDS.includes(event?.venue.id);
  const headerStartTime = offer?.passType ? offer?.passValidity.validFrom : event.startingTime;
  const headerEndingTime = offer?.passType ? offer?.passValidity.validUntil : event.endingTime;
  const lang = i18n.language;
  const [logoUrl, setLogoUrl] = useState('');

  return (
    <WrapperBackground>
      <AnalyticsHeader channel={channel} setLogoUrl={setLogoUrl} />
      <Container>
        <Wrapper>
          {!!logoUrl && (
            <Image
              fixed={{ height: 86, width: 86 }}
              src={logoUrl}
              options={{ q: 100 }}
              style={{ borderRadius: '10px;', paddingLeft: '36px;' }}
            />
          )}

          <InfoWrapper>
            <Info.Date
              startingTime={headerStartTime}
              endingTime={headerEndingTime}
              lang={lang}
              timeZone={event.venue.city.timezone}
            >
              {({ startDate, startTime, endDate, endTime }) => (
                <Tag>
                  {isLongerThanTwelveHours(headerStartTime, headerEndingTime)
                    ? `${startDate} ${startTime} - ${
                        isTillClosingActive
                          ? t('eventPage:endingTimeTillClosing')
                          : `${endDate} - ${endTime}`
                      }`
                    : `${startDate} | ${startTime} - ${
                        isTillClosingActive ? t('eventPage:endingTimeTillClosing') : `${endTime}`
                      }`}
                </Tag>
              )}
            </Info.Date>
            <Info.Name>
              {offer?.passType
                ? getLanguageTranslation(offer.name, lang, offer.passType.replace('_', ''), t) ||
                  offer.typeText
                : event.name}
            </Info.Name>
          </InfoWrapper>
        </Wrapper>
      </Container>
    </WrapperBackground>
  );
};

Header.propTypes = {
  offer: PropTypes.object,
};

Header.defaultProps = {
  offer: undefined,
};
