import { useCallback, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { GoogleRecaptchaController } from '@services/apiClient/interfaces/GoogleCaptchaController';
import { useAuth } from '@services/authClient/useAuth';
import PropTypes from 'prop-types';

import { Box } from '@xceedsrl/jukebox';

export const GoogleRecaptcha = ({ needsCaptcha, actionName, setCaptchaValid }) => {
  const ENGLISH_LOCALE = 'en';
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isRecaptchaV2Enabled, setIsRecaptchaV2Enabled] = useState(false);
  const { verifyCaptcha } = useAuth();

  const handleCaptchaV2 = useCallback(
    async captchaCode => {
      const isCaptchaValid = await verifyCaptcha(captchaCode);
      setCaptchaValid(isCaptchaValid);
    },
    [setCaptchaValid]
  );

  const handleCaptchaV3 = useCallback(async () => {
    let score = sessionStorage.getItem('google_captcha');
    let token = '';
    if (!executeRecaptcha) {
      return;
    }

    if (!score) {
      try {
        token = await executeRecaptcha(actionName);
        score = await GoogleRecaptchaController.getValidation({ token });
      } catch (_) {
        score = 0;
      }
      sessionStorage.setItem('google_captcha', score);
    }

    if (score > 0.5) {
      setCaptchaValid(true);
      setIsRecaptchaV2Enabled(false);
    } else {
      setIsRecaptchaV2Enabled(true);
    }
  }, [executeRecaptcha]);

  useEffect(() => {
    if (needsCaptcha) handleCaptchaV3();
    else {
      const element = document.getElementsByClassName('grecaptcha-badge');
      if (element[0]) element[0].remove();
    }
  }, [needsCaptcha, handleCaptchaV3, document]);

  return (
    <Box marginY={4}>
      {isRecaptchaV2Enabled && (
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_PUBLIC_KEY}
          theme="light"
          hl={ENGLISH_LOCALE}
          onChange={handleCaptchaV2}
        />
      )}
    </Box>
  );
};

GoogleRecaptcha.propTypes = {
  needsCaptcha: PropTypes.bool,
  setCaptchaValid: PropTypes.func.isRequired,
  actionName: PropTypes.string.isRequired,
};

GoogleRecaptcha.defaultProps = {
  needsCaptcha: null,
};
