import { Event } from '@models/Event';

import hosts from '../hosts';
import request from '..';

const offerTypes = {
  ticket: 'tickets',
  guestlist: 'guest-lists',
  bottleservice: 'bottle-services',
};

export class EventController {
  static getInfo = async ({ legacyId, id, channel }) => {
    const event = await request({
      path: `/v1/events/${legacyId || id}`,
      baseUrl: hosts.EVENTS_SERVICE,
      queryStrings: {
        channel,
      },
    });
    return new Event(event);
  };

  static registerPageView(id, channel) {
    return request({
      path: `/v1/events/${id}/visit?channel=${channel}`,
      method: 'POST',
      baseUrl: hosts.EVENTS_SERVICE,
    });
  }

  static holdTicketTableManagement = ({ tableManagementOfferId, quantity }) =>
    request({
      method: 'POST',
      path: `/v1/offers/${tableManagementOfferId}/hold`,
      baseUrl: hosts.TABLE_MANAGEMENT,
      body: {
        quantity,
      },
    });

  static getOfferById = ({ id, channel, admissionType, lang }) => {
    const offerType = offerTypes[admissionType];
    return request({
      path: `/v1/${offerType}/${id}`,
      baseUrl: hosts.OFFER_SERVICE,

      queryStrings: {
        channel,
        lang,
      },
    });
  };
}
